<h2 class="text-center">Passwort ändern</h2>

<form [formGroup]="angForm" (ngSubmit)="userchangepassword(angForm)" autocomplete="off" >
         
    <br>
    
    <div class="field">           
        <span for="password">Passwort</span>
        <br>
        <input type="password" name="password" formControlName="password"  autocomplete="off" class="form-control input-sm" placeholder="Passwort">
    </div>    
    
    <br>

    <div class="field">           
        <span for="newpassword">Neues Passwort</span>
        <br>
        <input type="password" name="newpassword" formControlName="newpassword"  autocomplete="off" class="form-control input-sm" placeholder="Neues Passwort">
    </div>    
    
    <br>
    
    <div >
		<span> Passwort wiederholen  </span>
        <br>        
        <input matInput type="password" placeholder="Passwort wiederholen" 
               formControlName="confirmPassword" [errorStateMatcher]="matcher">
        <br>  
        <mat-error *ngIf="angForm.hasError('notSame')">
            <br>
            <span style="color: crimson;">
                    	Passwörter nicht gleich! 
            </span>
            <br>
		</mat-error>  
	</div>

    
    <br>
    <button type="submit" class="btn btn-primary" [disabled]="!angForm.valid">ändern</button>

</form>
 
<br>
<br>
<br>       
<br>
<br>
<br>
<br>
<br>
<br>
<br>


    
 


