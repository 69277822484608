import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-rect',
  templateUrl: './rect.component.html',
  styleUrls: ['./rect.component.scss']
})
export class RectComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
