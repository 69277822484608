import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-page-vbawertefinden',
  templateUrl: './page-vbawertefinden.component.html',
  styleUrls: ['./page-vbawertefinden.component.scss']
})
export class PageVBAWertefindenComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
