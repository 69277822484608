<h2 class="text-center">Registration</h2>

<p style="color: crimson; font-weight: bold;">Dieser Teil befindet sich noch im Aufbau. </p>
<p style="color: crimson; font-weight: bold;">Eine Regiestrierung ist zurzeit leider nur als Tester möglich! </p>
<p style="color: crimson; font-weight: bold;">Ihre hier eingegeben Daten werden evtl. ohne Vorwarnung gelöscht.</p>


<form [formGroup]="angForm" (ngSubmit)="postdata(angForm)" autocomplete="off" >
    
    <div class="field">
    <span for="name">Benutzername: </span>
    <br>
    <input type="text" name="name" formControlName="name" autocomplete="off" class="form-control input-sm" placeholder="Benutzername">
    </div>    
                
    <br>
    
    <div class="field">
    <span for="email">EmailAddresse</span>
    <br>
    <input type="email" name="email" formControlName="email" autocomplete="off" class="form-control input-sm" placeholder="EmailAddresse">
    </div>    

    <br>
    <div class="field">           
        <span for="Password">Passwort</span>
        <br>
        <input type="password" name="Password" formControlName="password"  autocomplete="off" class="form-control input-sm" placeholder="Passwort">
    </div>    
    

    <br>
    
    <div >
		<span> Passwort wiederholen  </span>
        <br>        
        <input matInput type="password" placeholder="Passwort wiederholen" 
               formControlName="confirmPassword" [errorStateMatcher]="matcher">
        <br>  
        <mat-error *ngIf="angForm.hasError('notSame')">
            <br>
            <span style="color: crimson;">
                    	Passwörter nicht gleich! 
            </span>
            <br>
		</mat-error>  
	</div>

    
    <br>
    <button type="submit" class="btn btn-primary" [disabled]="!angForm.valid">Registrieren</button>

</form>

<div id ="bannerMSG" class = "hidden">
    <span>Regiestrierung erfolgreich! Es wird Ihnen ein Aktivierungslink an Ihre EmailAdresse geschickt!</span>
</div>

<br>
<br>
<br>       
<br>
<br>
<br>
<br>
<br>
<br>
<br>
  
 
