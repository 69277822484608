import { Component} from '@angular/core';
import { HttpClient } from '@angular/common/http'; 


//PHP einbindung per constructor=> startet items.php backend aus: httpdocs?!


@Component({
  selector: 'app-page-blockz',
  templateUrl: './page-blockz.component.html',
  styleUrls: ['./page-blockz.component.scss']
  
  
  
})


export class PageBLOCKZComponent  {
  
  title = 'blockZ';
  data = [];
  //constructor lädt auch ohne Seite betreten vor?
  constructor(private http: HttpClient) {
    
    // LOCAL TEST
    //this.http.get('http://localhost/items.php').subscribe(data => {
    // REAL !!!! 
    this.http.get('https://ofdev.de/items.php').subscribe(
      data => {  
      
        this.data.push(data);
        console.log(this.data);
          
      },
       
      error => console.error(error)

    );
  
  }

  




}  