<div id ="footer" class="foot">
    <p>

    <span style="margin-right: 20px;"> Version: 03.12.2023  
    </span>

    <a href="mailto:info@ofdev.de"  style="margin-right: 20px;">info@ofdev.de</a>                  

    <a routerLink ="/Datenschutz" style="margin-right: 20px;">Datenschutzerklärung</a>
   
    <a routerLink ="/cookies" style="margin-right: 20px;">cookies</a> 

    </p>
</div>
