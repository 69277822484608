<br>
<h1>Wie und wo kann ich den VBA Editor öffnen? Wo kann ich schreiben? </h1>
<div class="spaceH1"></div>

<br>
<h2>Schritt 1: Arbeitsmappe mit Makros(*.xlsm) erstellen: </h2>
   <p> Excel starten dort  Datei\ Speichern unter\... 
      und als Arbeitsmappe mit Makros(*.xlsm) speichern.
   </p>
   <img class="img_VBA" src="assets/images/pageVBAintro/xlsm.png" alt="assets/images/pageVBAintro/xlsm.png">
   
   <br>
<h2>Schritt 2:  VisualBasic-Editor öffnen: </h2>
   <p> Zum Entwicklermodus navigieren und per Klick öffnen. </p>
   <img class="img_VBA" src="assets/images/pageVBAintro/entwicklermodus.png" alt="assets/images/pageVBAintro/entwicklermodus.png">
   <br>
<h2>Schritt 3: Modul einfügen: </h2>
    <p> Tabelle1 markieren und dann rechts Klick: Einfügen\Modul auswählen. </p>
   <img class="img_VBA" src="assets/images/pageVBAintro/einfugenModul.png" alt="assets/images/pageVBAintro/einfugenModul.png">
   <br>
<h2>Schritt 4: speichern. </h2>
   <p> Hier kann nun VBA geschrieben werden. </p>
   <img class="img_VBA" src="assets/images/pageVBAintro/modul1.png" alt="assets/images/pageVBAintro/modul1.png">
   <br>

<h2>Ende.</h2>   

