
// <!-- OUT OUT 

// ALT WEG 

// nicht verwenden -->

import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, NgForm } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { DataserviceService } from '../dataservice.service';
import { Router } from '@angular/router';
import { first } from 'rxjs/operators';


@Component({
  selector: 'app-mylist',
  templateUrl: './mylist.component.html',
  styleUrls: ['./mylist.component.scss']
})


export class MylistComponent implements OnInit {

  datapool = [];
  datasp1 =[];
  datasp2 =[];
  datasp3 =[];
  
  data=[];
  delIDs=[];
  additemDATAs=[];

  
  angForm: FormGroup;
  
  constructor(private fb: FormBuilder,private http: HttpClient, private dataService: DataserviceService, private router:Router) {
    
    this.angForm = this.fb.group({
 
     name: ['', [Validators.required,Validators.minLength(1)]],
     spalte: ['', Validators.required],
     dauer: ['', Validators.required] 
    });
   


  }
   
  ngOnInit(): void {
  
    this.getpooldata('pool',this.datapool);
    this.getpooldata('spalte1',this.datasp1);
    this.getpooldata('spalte2',this.datasp2);
    this.getpooldata('spalte3',this.datasp3);
    
     
  }


  dragtableID;
  dragid;
  dragname;  
  dragdauer;
  indexfrom;

  public dragStart($event){
    console.log("dragging");
    
    this.dragtableID =$event.target.parentNode.parentNode.id;
    console.log("DragtableID: " + this.dragtableID);
    
    var td1from = $event.target.children[0];
    this.indexfrom = td1from.parentNode.rowIndex-1;
    console.log("Indexfrom: " + this.indexfrom);
    
    this.dragid = this.dragid = $event.target.children[0].innerText;
    console.log("DragID: " +this.dragid);
    
    this.dragname = $event.target.children[1].innerText;
    console.log("Dragname: " +this.dragname);
    
    this.dragdauer = $event.target.children[2].innerText;
    console.log("Dragdauer: " +this.dragdauer);
    
  
  };

  public drop($event){
    console.log("drop ausgeführt");
    
    //lol .parentNode.parentNode.parentNode ist richtig
    var droptableID =$event.target.parentNode.parentNode.parentNode.id;
    console.log("DroptableID: " + droptableID);
       
    var indexto = $event.target.parentNode.rowIndex-1;
    console.log("Indexto: " +indexto);

    // 1.  D E L E T E  the "old" dragged from his "old" HTML-TABLE cause its allready DROPPED
                    // after @ 2. insert otherwise #Indexroulet: change positions and deleting
    
    console.log("del Indexfrom: " + this.indexfrom); 
    //von welchem html-table kam das dragged? dann... "löschen" einfügen
    switch(this.dragtableID){
      
      case "tablepool":   
         
        this.datapool[0].splice(this.indexfrom,1);                        
      break; 
        
      case "tablespalte1":
         
        this.datasp1[0].splice(this.indexfrom,1);
      break;  

      case "tablespalte2":
         
        this.datasp2[0].splice(this.indexfrom,1);
      break;  

      case "tablespalte3":
        
        this.datasp3[0].splice(this.indexfrom,1);
      break;  

    }


    // 2. NOW I N S E R T dragged to   HTML-TABLE at index..,  .splice(at index, remove:index, data)
    
    var insert;
        
    insert={
          "id": this.dragid,
          "name": this.dragname,
          "position":indexto,
          "dauer": this.dragdauer
                    
        };  
    //zu welchem html-table gedragged and dropped? dann... "insert" einfügen
    switch(droptableID){
      
      case "tablepool":   
         
        this.datapool[0].splice(indexto,0,insert);                         
      break; 
        
      case "tablespalte1":
        
        this.datasp1[0].splice(indexto,0,insert);
      break;  

      case "tablespalte2":
        
        this.datasp2[0].splice(indexto,0,insert);
      break;  

      case "tablespalte3":
         
        this.datasp3[0].splice(indexto,0,insert);
      break;  

    }     
        

  };
  
  public allowdrop($event){
    $event.preventDefault();
    console.log("allowdrop");
  };
  
  



  
  getpooldata(sqlspalte,datafor){

    //check !User; check who;
    const  username  = this.dataService.getToken();
    
    if(!username){

      console.log("Um Ihre Liste zu sehen, bitte einloggen");
      
      var bannerMSG = document.getElementById('bannerMSG1');
      // Show Message:
          bannerMSG.classList.remove('hidden'); 

      return;

    }
        
    if(datafor ==this.datapool){
    
      this.dataService.userpooldata(username, sqlspalte)
        .pipe(first())
        .subscribe(
          data =>{

            this.datapool.push(data);
                                   
          },
          error => {
              
            console.log(error);
              
          });
        
    } 
    
    if(datafor ==this.datasp1){
    
      this.dataService.userpooldata(username, sqlspalte)
        .pipe(first())
        .subscribe(
          data =>{

            this.datasp1.push(data);

          },
          error => {
              
            console.log(error);
              
          });
        
    } 

    if(datafor ==this.datasp2){
    
      this.dataService.userpooldata(username, sqlspalte)
        .pipe(first())
        .subscribe(
          data =>{

            this.datasp2.push(data);                  

          },
          error => {
              
            console.log(error);
              
          });
        
    } 

    if(datafor ==this.datasp3){
    
      this.dataService.userpooldata(username, sqlspalte)
        .pipe(first())
        .subscribe(
          data =>{
                  
            this.datasp3.push(data);

          },
          error => {
              
            console.log(error);
              
          });
        
    } 


  
  
  }

  
  additemtoDB(angForm1){
    //check ob eingelogged also token vorhanden
    const  username  = this.dataService.getToken();
    
    if(!username){

      console.log("Um Einträge hinzuzufügen bitte einloggen!");
      
      var bannerMSG = document.getElementById('bannerMSG2');
      // Show Message:
          bannerMSG.classList.remove('hidden');
      //this.router.navigate(['login']);    

      return;

    }
    
    const spaltenarray = ["pool", "spalte1", "spalte2","spalte3"];
    if(!spaltenarray.includes(angForm1.value.spalte)){

      alert("Das Feld Spalte darf nur folgende einträge verwenden: "+ spaltenarray);

      return;
    }
    // zukünftige position ermitteln = länge +1 da später .push() dann add dann Spalte aktualisieren
        
    // B R O K E N ! !! !!   !

    switch(angForm1.value.spalte){
      case "pool":   
      var position = this.datapool[0].length +1 ;                           
      break;
      case "spalte1":
        var position = this.datasp1[0].length +1;   
      break;
      case "spalte2":
        var position = this.datasp2[0].length +1;     
      break;
      case "spalte3":
        var position = this.datasp3[0].length +1; 
      break;

    }
        
    // B R O K E N ! !! !!   !
    this.dataService.additemtoDB(angForm1.value.spalte, angForm1.value.position, angForm1.value.name, angForm1.value.dauer, angForm1.value.termin,angForm1.value.menge, username)
      .pipe(first())
      .subscribe(
          data => {
                console.log(data);
                //Eintrag in Table hinzufügen 
                //mit den selben "INSERT"  to DB data[] .. aber nicht nochmal aus DB holen ;)
                var spalte = data['spalte'];
                
                var id = data['id'];
                var name = data['name'];
                var position = data['position'];
                var dauer = data['dauer'];
                
                
                var insert ={
                  "id": id,
                  "name": name,
                  "position": position,
                  "dauer": dauer
                }

                switch(spalte){
                  case "pool":   
                  var position = this.datapool[0].length;  
                 
                  this.datapool[0].push(insert);
                                       
                  break;
                  case "spalte1":
                   var position = this.datasp1[0].length; 
                  
                  this.datasp1[0].push(insert);
                                    
                  break;
                  case "spalte2":
                    var position = this.datasp2[0].length; 
                   
                  this.datasp2[0].push(insert);
                     
                  break;
                  case "spalte3":
                    var position = this.datasp3[0].length; 
                                    
                  this.datasp3[0].push(insert);
                     
                  break;

                }
                
          
          },
          error => {
              alert("Bitte alle Felder ausfüllen");
          });
  }



  save_changes_to_DB($event){
    console.log("speichern to DB geklickt!");
    
    const  username  = this.dataService.getToken();
    
    if(!username){

      console.log("Um änderungen zu speichern einloggen!");
      
      var bannerMSG = document.getElementById('bannerMSG1');
      
          bannerMSG.classList.remove('hidden');   

      return;

    }

    // atm Positionen aus den Spalten/table in die data...[0] schreiben
    var arrayDATAs=[this.datapool[0], this.datasp1[0], this.datasp2[0], this.datasp3[0]];

    for(var data0 of arrayDATAs){

      for( var item of data0){
                    
          var atm_array_position = data0.indexOf(item);
                    
          item["position"] = atm_array_position;
      
      }
    
    }  
    

    this.dataService.save_changes_to_DB(username,this.datapool[0],this.datasp1[0],this.datasp2[0],this.datasp3[0])
      .pipe(first())
      .subscribe(
          data => {
          
            console.log("Update Status: OK");

          });
  
    console.log("Update done.");        

  }



  delete_from_DB($event){
    console.log("löschen geklickt");  
    console.log($event);
    
    // check USER
    const  username  = this.dataService.getToken();
    
    if(!username){

      console.log("Um zu löschen bitte einloggen!");
      
      var bannerMSG = document.getElementById('bannerMSG1');
      
          bannerMSG.classList.remove('hidden');   

      return;

    }

    var delSpalteID = $event.target.parentNode.parentNode.parentNode.id;
    console.log(delSpalteID);
    
    // rowIndex dann -1 da der table eine Überschrift hat das data[] aber nicht!
    var delROW = $event.target.parentNode.rowIndex;
    console.log(delROW);  
 
    var delarrayposDATA;
    var spalte;
    var datatable;
    switch(delSpalteID){
      case "tablepool":
        spalte ="pool";   
        delarrayposDATA = this.datapool[0][delROW-1];
        datatable = this.datapool[0];                            
      break;
      case "tablespalte1":
        spalte = "spalte1";
        delarrayposDATA = this.datasp1[0][delROW-1];
        datatable = this.datasp1[0];   
      break;
      case "tablespalte2":
        spalte = "spalte2";
        delarrayposDATA = this.datasp2[0][delROW-1]; 
        datatable = this.datasp2[0];     
      break;
      case "tablespalte3":
        spalte = "spalte3";
        delarrayposDATA = this.datasp3[0][delROW-1]; 
        datatable = this.datasp3[0];
      break;

    }
    console.log(spalte);
    console.log(delarrayposDATA );
    console.log(delarrayposDATA['id']);
    //Confirm delete
          
    if (confirm("Soll: " + delarrayposDATA["name"] + " gelöscht werden?" )) {
     
      //zu delIDs hinzufügen
      this.delIDs.push(delarrayposDATA["id"]);
      console.log(delarrayposDATA["id"]);
      //aus table/data[] löschen
      datatable.splice(delROW-1,1); 
      
      //service to DB
      this.dataService.delete_from_DB(username,this.delIDs)
        .pipe(first())
        .subscribe(
            data => {
            
              console.log("gelöscht");

            });
            
      console.log("delete done.");        
    
    } else {
       console.log("löschen abgebrochen!"); 
    }        
  
  
  }
  
  
  
  
  
  
    


 
  



}

