<br>
<H1>Kommentare einfügen!</H1>
<div class="spaceH1"></div>

<p>Um einen einfachen Kommentar einzufügen: </p>

<iframe id=iframe1
    src="assets/txt/pageVBAKommentar/1.txt" 

    
></iframe>

<p>Um Kommentare ein/auszublenden: Excel-Tabellen-Reiter 
    Überprüfen: Kommentare</p>

<h2>Kommentare einfügen Beispiel</h2>

<p>Sie haben eine Excel-Tabelle und möchten Kommentare einfügen?</p>

<h2>Schritt 1: Leere Arbeitsmappe erstellen (*.xlsm) und ein Modul einfügen</h2>
<p>Hinweis: <a routerLink="/VBAintro" > VBA Editor Wie? und Wo? </a> </p>
 
<h2>Beispiel-Tabelle mit erfundenen Daten.</h2>

<object data="assets/tabellen/Demostatistik.html" type="text/html"

        style="height:500px"

></object>

<h2>Schritt 2: Ein Sub für den Aufruf und eines für den Code erstellen.</h2> 

<p>Im Sub addComment ist vorallem wichtig das ein bereits vorhandener Kommentar aus
    der Zelle gelöscht wird! .ClearComments sorgt dafür. </p>

<iframe id=iframe2
    src="assets/txt/pageVBAKommentar/2.txt" 

       
></iframe>

<p>Nun kann durch das Aufrufen von addComment(...) ein Kommentar in dieser Zelle
     erstellt werden.
</p>

<br>


<h2>Schritt 3: Kommentar einfügen Wenn...</h2>

<p>
    Wenn der Name "Jamie" ist soll ein Kommentar erstellt werden.
    Der Code von Oben wird vor dem End Sub um die Variablen spnrVerkäufer, lastRow, i erweitert.
    Deren Werte werden ermittelt. Alle Kommentare der Verkäufer-Spalte werden gelöscht.
    Es wird eine FOR-Schleife benutzt um den Kommentar zu erzeugen.
    Das Sub addComment(.....) bleibt unverändert. </p>

<iframe id=iframe3
    src="assets/txt/pageVBAKommentar/3.txt" 

       
></iframe>

<p>Hinweis: Es könnte auch ein Button zum starten erstellt werden.
    <a routerLink="/VBAbutton" > Makro Start Button erstellen</a>
</p>
<p>Hinweis: Für die Beispiel-Tabelle könnte ein Import erstellt werden.
    <a routerLink="/VBAcopyTO" > Makro import erstellen</a>
</p>
<h2>Ende</h2>

