import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators, NgForm,FormGroupDirective } from '@angular/forms';
import { first } from 'rxjs/operators';
import { Router } from '@angular/router';
import { DataserviceService } from '../dataservice.service';
import {ErrorStateMatcher} from '@angular/material/core';

export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const invalidCtrl = !!(control && control.invalid && control.parent.dirty);
    const invalidParent = !!(control && control.parent && control.parent.invalid && control.parent.dirty);

    return (invalidCtrl || invalidParent);
  }
}



@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss']
})

export class RegistrationComponent implements OnInit {
  angForm: FormGroup;
  error = '';
  success = '';
  data=[];

  matcher = new MyErrorStateMatcher();

  constructor(private fb: FormBuilder,private dataService: DataserviceService,private router:Router) {
 
    this.angForm = this.fb.group({
      email: ['', [Validators.required,Validators.minLength(1), Validators.email]],
      password: ['', Validators.required],
      name: ['', Validators.required],
      confirmPassword: ['', [Validators.required]]
    
    }, {
      validators: this.checkPasswords
    });
  
  }
  
  checkPasswords(group: FormGroup) { // here we have the 'passwords' group
    let pass = group.controls.password.value;
    let confirmPass = group.controls.confirmPassword.value;

    return pass === confirmPass ? null : { notSame: true }
  }
  
  ngOnInit() {
  }
  //postdata(angForm1:NgForm) lol lol lol
  postdata(angForm1)
  {
    this.dataService.userregistration(angForm1.value.name,angForm1.value.email,angForm1.value.password)
      .pipe(first())
      .subscribe(
          data => {
              // hier auf meldungen von registration.PHP checken???!!!
                            
              if(data['emailexists']==='yes'){
                console.log("mail: '" + data['email'] +"' bereits vorhanden!");
                alert("Email: '" + data['email'] + "' bereits vorhanden!" )
              }
              if(data['nameexists']==='yes'){
                console.log("name: '"+ data['name'] +  "' bereits vorhanden!");
                alert("Benutzername: '" + data['name'] + "' bereits vorhanden!" )
              }
              if(data['emailexists'] ==='no' 
              && data['nameexists']==='no'){
                console.log("registration erfolgreich");
                alert("Regiestrierung erfolgreich! Es wird Ihnen ein Aktivierungslink an Ihre EmailAdresse geschickt!");             
                
                var bannerMSG = document.getElementById('bannerMSG');
                // Show Message:
                bannerMSG.classList.remove('hidden');
                //this.router.navigate(['login']);               
              
              }  
          },
          err => {

            console.log(err);

            this.error =err;
            
          });
  }
  get email() { return this.angForm.get('email'); }
  get password() { return this.angForm.get('password'); }
  get name() { return this.angForm.get('name'); }
  
  
  
}
 

