import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-page-vbaentfernen',
  templateUrl: './page-vbaentfernen.component.html',
  styleUrls: ['./page-vbaentfernen.component.scss']
})
export class PageVBAentfernenComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
