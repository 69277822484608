<h1>Test</h1>

<!-- <div class="rect">
    <object data="assets/txt/test/1.txt" type="text/html" 
     width="1000x"
     height="1000px"
    ></object>
</div>  -->

<iframe id=iframe1
    src="assets/txt/test/1.txt" 
    width="1000x"
    height="1000px"
    scrolling="no" 
    
></iframe>
<iframe id =iframe2
    src="assets/txt/test/1.txt" 
    width="1000x"
    height="1000px"
    scrolling="no" 
    

></iframe>


