

<br>
<h1> Willkomen auf ofdev.de :)</h1>
<div class="spaceH1"></div>

<div >

   
  <div class="rect">


    <p>
      Hier gibt es:
      <br>
      <br>
      - VBA(Excel):  Bearbeitung / Sortierung /Auswertung von Excel-Tabellen + Programmcode-Beispielen

      <br>

      <button onclick="document.getElementById('btn_nav_vba').click()"> VBA</button>
      <br>

      <span style="color: blue;">   </span>
      <br>
      <br>
      - Web: Demo einer verschiebbaren Web-Liste die auch auf Smartphones funktioniert

      <br>
      <button onclick="document.getElementById('btn_nav_web').click()"> Web</button>
      <br>

      <br>
      <br>
      <br>
    </p>

    <br>
    Mail: <a href="mailto:info@ofdev.de" style="margin-right: 20px;">info@ofdev.de</a>
    <p>

    </p>


    <div style="background-color: white; font-size: 40px;
                font-weight: bold; color: grey;">
      <img src="ofdev.ico" style="width:60px; height:60px" alt="ofdev.ico"> ofdev
    </div>





  </div>
            
</div>            
            
     
<br>

