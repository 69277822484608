  

     
 
 
<br>
<h1>Spalten finden </h1>
<div class="spaceH1"></div>
<br>
<p>
    Wenn die Spaltenüberschriften z.B. in  Zeile1 stehen kann man diese dort 
    per Application.Match() suchen:
</p>



<iframe id=iframe1
    src="assets/txt/pageSpaltenfinden/1.txt" 

   
   
     
       
></iframe>



<h2>Spalten finden Beispiel:</h2>

<p>
Sie haben eine Excel-Tabelle und Rechnen immmer: Spalte A + Spalte D ist das Ergebnis 
für Spalte F ? Nun hat sich aber die Reihenfolge geändert?
</p>

<h2>Schritt 1: Leere Arbeitsmappe erstellen (*.xlsm) und ein Modul einfügen</h2>
<p>Hinweis: <a routerLink="/VBAintro" > VBA Editor Wie? und Wo? </a> </p>

<h2>Beispiel-Tabelle mit erfundenen Daten.</h2>

<object data="assets/tabellen/Demostatistik.html" type="text/html"
  style=" height:500px; width:450px"
></object> 
<h2>Schritt 2: Spalten finden</h2>  

<p>
Sub anlegen. Hier werden in das Array: arrColumnsToMatch die zu suchenden 
Spaltenüberschriften geschrieben. Diese werden in Zeile 1 das 
ThisWorkbook.Worksheets(sheetName).Rows(1) entspricht vermutet. Um den Spaltenüberschriften
die richtige Spaltennummer zuorden zu können
wird das Array: arrSpNr genutzt. Es muss die selbe Anzahl an Einträgen haben. Hier
wird 0,1,2,3 eingetragen, das wird später aber überschriebne und dient nur zur 
demonstration bzw. dimensionierung.
</p>    
<p>
Ablauf: Es wird eine For-Schleife 4mal daurchlaufen wobei i = 0 dann 1 dann 2 dann 3 
ist also 4mal das ist auch die Anzahl der Einträge in den beiden Arrays. UBound(arrColumnsToMatch) 
hat die Obergrenze des Arrays zurückgegeben. Nun wird arrSpNr(i) das jeweilige Ergebnis 
des "Matches" zugewiesen. Nicht gefunden Spalten werden im spErrorLOG 
eingetragen.
</p>
<p>
Nach der For-Schleife wird gecheckt ob eine Spalte nicht gefunden wurde, falls das der
Fall ist gibt es eine MsgBox und das Sub wird beendet. Falls aber alles gefunden wurde 
wird den Spalten nun die jeweilige Nummer zugewiesen.

</p>


<iframe id=iframe2
    src="assets/txt/pageSpaltenfinden/2.txt" 
    
     
    
    ></iframe>



<h2>Optional: Umsatz berechnen. </h2>

<p> Darunter im selben Modul letzte verwendete Zeile und Spalte ermitteln für 
    Tabelle "sheetName" Spalte 1 bzw. Zeile 1. Danach Umsatzt anlegen als Double. 
    In Zeile 1 als Spaltenüberschrift "Umsatz" einfügen und  für jede Zeile darunter
    per For-Schleife den Umsatz berechnen.
   
</p>

<iframe id=iframe3
    src="assets/txt/pageSpaltenfinden/3.txt" 
    
     
    
></iframe>

<br>
<p>Hinweis: Es könnte auch ein Button zum starten erstellt werden.
    <a routerLink="/VBAbutton" > Makro Start Button erstellen</a>
</p>
<p>Hinweis: Für die Beispiel-Tabelle könnte ein Import erstellt werden.
    <a routerLink="/VBAcopyTO" > Makro import erstellen</a>
</p>

<h2>Ende</h2>
<br>





