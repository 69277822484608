
<h1> XML - Test - File:</h1>
<div class="spaceH1"></div>
<br>

 <button id="filebtn" class="BtnAddFiles" (click)="importData()">  Datei öffnen </button> 
  
 <p id ="fileAnzeige"></p>


<br>
<br>

<div class="container">    
  <table id = "xmlTable" class="tableOF">    
    <tr>    
      <th>Auftrag</th>    
      <th>Dauer</th>    
         
    </tr>  
    <br>  
    <tr *ngFor="let item of xmlItems">    
      <td>{{item.id}}</td>    
      <td>{{item.dauer}}</td>    
         
    </tr>    
  </table>    
</div>    
