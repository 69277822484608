
<!-- OUT OUT 

ALT WEG 

nicht verwenden -->

<div class="noselect">
<h2 >Meine Liste (TestVersion)</h2>
<p style="color: crimson; font-weight: bold;">Diese Funktion wird zurzeit erweitert!</p>
<p style="color: crimson; font-weight: bold;">Bitte nur zum Testen benutzen. Momentan ist kein Cloudservice möglich. </p>
<p style="color: crimson; font-weight: bold;">Danke für Ihr Verständnis. </p>
<br>
<div ></div>
<form [formGroup]="angForm" (ngSubmit)="additemtoDB(angForm)" autocomplete="off" class="noselect" >
    
  <div class="formfield">
  <span for="name">Name:</span>
  <br>
  <input type="name" name="name" formControlName="name" autocomplete="off" class="form-control input-sm" placeholder="Name">
  </div> 
  
  <div class ="formfield">
  <span for="dauer">Dauer: </span>
  <br>
  <input type="dauer" name="dauer" formControlName="dauer"  autocomplete="off" class="form-control input-sm" placeholder="Dauer">
  </div>
  
  <div class ="formfield">
  <span for="spalte">Spalte: </span>
  <br>
  <input type="spalte" name="spalte" formControlName="spalte"  autocomplete="off" class="form-control input-sm" placeholder="Spalte">
  </div>
  
  <br>
  <br>
  <br>

  <button type="submit" class="btn btn-primary" [disabled]="!angForm.valid">erstellen</button>

</form>  



<br>

<div id ="bannerMSG1" class = "hidden">
  <span>Um Ihre Liste zu sehen bitte einlogen!</span>
</div>

<div id ="bannerMSG2" class = "hidden">
  <span>Um Einträge hinzuzufügen bitte einloggen!</span>
</div>

<!-- Drag and Drop achtung andere namen wegen TS: ondrop = drop; ondragover = dragover; drag=dragstart; -->


<br>
<table id="tablepool"  
        
        (drop)="drop($event)"
        (dragover)="allowdrop($event)"       
>
<thead>
    <tr>
      <th>ID</th>
      <th>Pool</th>
      <th>dauer</th>
    </tr>    
</thead>
<tbody>    
        <tr *ngFor="let mydata of datapool[0]" 
        [ngStyle] = "{'height.px': mydata.dauer * 0.5}"
        draggable="true"
        (dragstart)="dragStart($event)"
        >
        <td>{{mydata.id}}</td>
        <td>{{mydata.name}}</td>
        <td>{{mydata.dauer}}</td>   
        <button (click)="delete_from_DB($event)">x</button>
        </tr>
</tbody>      
</table>    



<table id="tablespalte1"  
        
        (drop)="drop($event)"
        (dragover)="allowdrop($event)"       
>
<thead>
    <tr>
        <th>ID</th>
        <th>Spalte1</th>
      <th>dauer</th>
    </tr>    
</thead>
<tbody>    
        <tr *ngFor="let mydata of datasp1[0]" 
        [ngStyle] = "{'height.px': mydata.dauer * 0.5}"
        draggable="true"
        (dragstart)="dragStart($event)"
        >
        <td>{{mydata.id}}</td>
        <td>{{mydata.name}}</td>
        <td>{{mydata.dauer}}</td>
        <button (click)="delete_from_DB($event)">x</button>   
        </tr>
</tbody>      
</table>    

<table id="tablespalte2"  
        
        (drop)="drop($event)"
        (dragover)="allowdrop($event)"       
>
<thead>
    <tr>
        <th>ID</th>
        <th>Spalte2</th>
      <th>dauer</th>
    </tr>    
</thead>
<tbody>    
        <tr *ngFor="let mydata of datasp2[0]" 
        [ngStyle] = "{'height.px': mydata.dauer * 0.5}"
        draggable="true"
        (dragstart)="dragStart($event)"
        >
        <td>{{mydata.id}}</td>
        <td>{{mydata.name}}</td>
        <td>{{mydata.dauer}}</td>
        <button (click)="delete_from_DB($event)">x</button>   
        </tr>
</tbody>      
</table>    

<table id="tablespalte3"  
        
        (drop)="drop($event)"
        (dragover)="allowdrop($event)"       
>
<thead>
    <tr>
        <th>ID</th>
        <th>Spalte3</th>
      <th>dauer</th>
    </tr>    
</thead>
<tbody>    
        <tr *ngFor="let mydata of datasp3[0]" 
        [ngStyle] = "{'height.px': mydata.dauer * 0.5}"
        draggable="true"
        (dragstart)="dragStart($event)"
        >
        <td>{{mydata.id}}</td>
        <td>{{mydata.name}}</td>
        <td>{{mydata.dauer}}</td>
        <button (click)="delete_from_DB($event)">x</button>   
        </tr>
</tbody>      
</table>    

<!-- save to DB Button -->
<button (click)="save_changes_to_DB($event)">speichern</button>
        

</div>