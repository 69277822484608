
// Orginal bis 28.04.2021
// import { Component } from '@angular/core';

// @Component({
//   selector: 'app-root',
//   templateUrl: './app.component.html',
//   styleUrls: ['./app.component.scss']
// })
// export class AppComponent {
//   title = 'ofdev';
// }


// NEU ab 28.04.2021 soll routchanges erkennen für navbar, Routerlink, Routes

//////////////////////////////////////////////////////////////////////////
// M O B I L E  I M P R O V E M E N T S
//
// Since 2023...Mobile improvements!

// - NAV /Sidebar:
//   after routechanged check: window.matchMedia("(max-width:768px)").matches
//
///////////////////////////////////////////////////////////////////////////
// GLOBAL da sich alles im app.component.html befindet
// beware of ngOnInint: wird wohl nur beim betreten der Seite geladen!!!
// da es hier wie ein wrapper fungiert!!!

import { Component,OnInit } from '@angular/core'; 
import { Router, Event, NavigationStart, NavigationEnd, NavigationError,  } from '@angular/router';
import { isError } from 'node:util';
import {DataserviceService} from './dataservice.service';



@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],

    template: `<router-outlet></router-outlet>`

})

export class AppComponent implements OnInit{
    
  loginbtn:boolean;
  logoutbtn:boolean;

    constructor(private router: Router, private dataService: DataserviceService) {
               
        //router:
        this.router.events.subscribe((event: Event) => {
            if (event instanceof NavigationStart) {
                // Show loading indicator
                
            }

            if (event instanceof NavigationEnd) {
                // Hide loading indicator
                
                console.log("Route changed");

              //scroll to top
              window.scrollTo(0, 0);

              //footer an
              document.getElementById("footer").style.display = "block";
                    
              changecolor();

              //2023 mobile
              //screen smaller and move button!!! then 768px ...do..
              if (window.matchMedia("(max-width:768px)").matches) {

                //change wrapper size
                document.getElementById("wrapper").style.maxWidth = "650px";
                //hide sidebar
                document.getElementById("sidebar_frame_flex").style.display = "none";
                //move content_frame left
                document.getElementById("content_frame").style.marginLeft = "35px";
                console.log("routing finished! width now: L O W E R   768px sidebar= 'none'");

              } else {


                //change wrapper size
                document.getElementById("wrapper").style.maxWidth = "1000px";
                //show sidebar
                document.getElementById("sidebar_frame_flex").style.display = "block";
                //move content_frame left
                document.getElementById("content_frame").style.marginLeft = "230px";
                console.log("routing finished! width now: HIGHER   768px  sidebar= 'block'");
              }


            }

            if (event instanceof NavigationError) {
                // Hide loading indicator

                // Present error to user
                console.log(event.error);
            }
        });
        
        //dataService
        dataService.getLoggedInName.subscribe(name => this.changeName(name));
        if(this.dataService.isLoggedIn())
        {
        console.log("loggedin");
        this.loginbtn=false;
        this.logoutbtn=true
        }
        else{
        this.loginbtn=true;
        this.logoutbtn=false
        }
 
        
    
    }
    
    
    private changeName(name: boolean): void {
        this.logoutbtn = name;
        this.loginbtn = !name;
      }
      logout()
      {
        this.dataService.deleteToken();
        window.location.href = window.location.href;
      }    
    

  ngOnInit(): void {
    
        
  }   
      


}



// codecolor.js rewrite
// Alles temorär......mit timer .( 
//  quick and  dddd :D  
// sollte noch auf event / emitter umgestellt werden 2021 
async function changecolor(){
        
    console.log("Changecolor() iframe started:")
       
    //console.log('await sleep(5000);');
    await sleep(5000);

  var iframes = document.getElementsByTagName("iframe");
  var iframeCount = document.getElementsByTagName("iframe").length;
        
    console.log('iframes: ' + iframeCount)
    
    //console.log(document.getElementsByTagName("iframe"));
    
        for(var i = 0; i < iframeCount; i++){
         
        //   var iframetext = document.getElementsByTagName("iframe")[i].contentWindow.document.body.innerHTML;  
        //   console.log(iframetext);  
        //  var frame = document.getElementsByTagName("iframe")[i].contentWindow;
        //  var body = frame.document.getElementsByTagName("body")

         
         var body = await bodyIframe(i); 
         
         
           if(body){



              // color keywords
              regexIframe(body);

             //resize iframe
             var iframeID = iframes[i].id;

             console.log("2023 Iframe body");
             console.log(iframeID);
             console.log(body);
             console.log(body[0]);
             console.log(body[0].clientHeight); //want
             console.log(body[0].parentElement.clientHeight); //atm         
             document.getElementById(iframeID).style.height = body[0].clientHeight + 30 + 'px';

             //body[0].parentElement.style.overflowY = "hidden";


           }
         

        }

async function bodyIframe(i) {
    try{

        var body = await getIframeBody(i)
        
        console.log("await iFrame innerHTML succesess...");
        //DB console.log(document.getElementsByTagName("iframe")[i].contentWindow.document.body.innerHTML);
        
        return body;

    }
    catch(err){
        console.log("bodyIframe error");
        console.log(err);
    }
}

async function getIframeBody(i){
    
        //DB console.log('var frame = document.getElementsByTagName("iframe")[i].contentWindow;');
    
    var frame = document.getElementsByTagName("iframe")[i].contentWindow;
    
        //DB console.log(frame);
       
    var body = frame.document.getElementsByTagName("body");
    
        //DB console.log(body);

    var innerHTML = document.getElementsByTagName("iframe")[i].contentWindow.document.body.innerHTML;   
        
        //DB console.log("innerHTML:");
        //DB console.log(innerHTML);
    
    //Soll wenn leer solange wiederholen bis dann wohl geladen ist!
    // klappt aber nicht so wirklich xD
    if(!innerHTML){
            var x = 0;
            while(innerHTML ==="" &&  x < 20){
                console.log("async innerhtml: <Empty> ... retry ... " + (x + 1) + "x" )
                console.log("await sleep(2000)")
                await sleep(2000);
                  //DB  console.log('innerHTML = document.getElementsByTagName("iframe")[i].contentWindow.document.body.innerHTML;')
// Crash ist  hier wenn conten nicht aus Seite ;             
                    
                innerHTML = document.getElementsByTagName("iframe")[i].contentWindow.document.body.innerHTML; 
                console.log(innerHTML);

//Crash ENDE              
                
                frame = document.getElementsByTagName("iframe")[i].contentWindow;
                body = frame.document.getElementsByTagName("body");
                //console.log(innerHTML);
                x = x + 1;
            
            }       
        
        }  
    return body;

}


function regexIframe(body){
    //REGEX
    var reCol = 'deepskyblue';
    var green ='forestgreen'; 
    
    //regex for :  ' .....Komentar 442as%342.... '
    var regexforComm = /(?<=\')(.*?)(?=\')/;

    
    if(regexforComm.exec(body[0].outerHTML)){        

      
        while (regexforComm.exec(body[0].outerHTML)){

         //lol typeErroRcrap          
                       
         var matched =regexforComm.exec(body[0].outerHTML);
         
         // console.log("matched: "+matched[1]);
         
         body[0].outerHTML =
         body[0].outerHTML.replace(/(?<=\')(.*?)(?=\')/,'<span style="color:'+green+';">'+matched[1]+'</span>');
         
         body[0].outerHTML =
         body[0].outerHTML.replace(/'''/,"");

        } 
    
    }          

    body[0].outerHTML =
    body[0].outerHTML.replace(/Option Explicit/g,'<span style="color:'+reCol+';">Option Explicit</span>')
    body[0].outerHTML =
    body[0].outerHTML.replace(/Sub/g,'<span style="color:'+reCol+';">Sub</span>')
    body[0].outerHTML =
    body[0].outerHTML.replace(/End/g,'<span style="color:'+reCol+';">End</span>')
    body[0].outerHTML =
    body[0].outerHTML.replace(/Exit/g,'<span style="color:'+reCol+';">Exit</span>')
    body[0].outerHTML =
    body[0].outerHTML.replace(/Dim/g,'<span style="color:'+reCol+';">Dim</span>')
    body[0].outerHTML =
    body[0].outerHTML.replace(/ReDim/g,'<span style="color:'+reCol+';">ReDim</span>')
    body[0].outerHTML =
    body[0].outerHTML.replace(/As String/g,'<span style="color:'+reCol+';">As String</span>')
    body[0].outerHTML =
    body[0].outerHTML.replace(/As Integer/g,'<span style="color:'+reCol+';">As Integer</span>')
    body[0].outerHTML =
    body[0].outerHTML.replace(/As Variant/g,'<span style="color:'+reCol+';">As Variant</span>')
    body[0].outerHTML =
    body[0].outerHTML.replace(/As Object/g,'<span style="color:'+reCol+';">As Object</span>')
    body[0].outerHTML =
    body[0].outerHTML.replace(/As Boolean/g,'<span style="color:'+reCol+';">As Boolean</span>')
    body[0].outerHTML =
    body[0].outerHTML.replace(/As Double/g,'<span style="color:'+reCol+';">As Double</span>')
    body[0].outerHTML =
    body[0].outerHTML.replace(/For/g,'<span style="color:'+reCol+';">For</span>')
    body[0].outerHTML =
    body[0].outerHTML.replace(/Next/g,'<span style="color:'+reCol+';">Next</span>')
    body[0].outerHTML =
    body[0].outerHTML.replace(/While/g,'<span style="color:'+reCol+';">While</span>')
    body[0].outerHTML =
    body[0].outerHTML.replace(/Do\b/g,'<span style="color:'+reCol+';">Do</span>')
    body[0].outerHTML =
    body[0].outerHTML.replace(/Loop\b/g,'<span style="color:'+reCol+';">Loop</span>')
    body[0].outerHTML =
    body[0].outerHTML.replace(/If/g,'<span style="color:'+reCol+';">If</span>')
    body[0].outerHTML =
    body[0].outerHTML.replace(/Then/g,'<span style="color:'+reCol+';">Then</span>')
    body[0].outerHTML =
    body[0].outerHTML.replace(/Else/g,'<span style="color:'+reCol+';">Else</span>')
    body[0].outerHTML =
    body[0].outerHTML.replace(/End If/g,'<span style="color:'+reCol+';">End If</span>')
    
   
   }    



}


function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

