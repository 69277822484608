

<div id="ofdev_consent" class="hidden">
    
    <button id="closeBtn"  class="closeBtn" style="float:right"> Schließen X</button>
    
    <br>
        <p> Es werden ohne Zustimmung nur technisch erforderliche Cookies verwendet. 
            Das erneute Anzeigen dieses Hinweises kann mit einem Klick auf  7 Tage ausblenden
            7 Tage ausgeblendet werden, dafür wird ausschließlich zu diesem  Zweck ein Cookie in Ihrem
            Browser erstellt. Wenn Sie das ausblenden möchten klicken Sie auf 7 Tage ausblenden.
        </p> 
    <br> 

    <button id="acceptBtn" class="acceptBtn" >7 Tage ausblenden</button>
    
</div>
