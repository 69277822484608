<br>
<h1>VBA(Excel) Werte finden</h1>
<div class="spaceH1"></div>
<p>Sie haben einen Excel-Tabelle und wolllen Werte finden
    um damit zu rechen? Hier eine Idee:
</p>

<object data="assets/tabellen/DemoVerkaufszahlen.html" type="text/html"

  style="height:1000px"


></object>


<h2>Schritt 1: Leere Arbeitsmappe erstellen (*.xlsm) und ein Modul einfügen</h2>
<p>Hinweis: <a routerLink="/VBAintro" > VBA Editor Wie? und Wo? </a> </p>
 

<h2>Schritt 2: Modul einfügen und Sub erstellen</h2> 
<p>
Spalten-Nummern ermitteln in denen sich die zu suchenden Werte befinden.
Hier wird als Basis <a routerLink="/Spaltenfinden">Spalten finden und Rechnen</a>
verwendet. Die Variablen spnr... für die Spalten müssen angepasst werden und
auch die Namen in arrColumnsToMatch. Die letzte Zeile und letzte Spalte der 
Tabelle werden auch wieder ermittelt.
</p>

<iframe id=iframe1
    src="assets/txt/pageVBAwertefinden/1.txt" 

  
></iframe>


<p>
Sie möchten nun ausrechen wieviel Umsatz "Taylor" und "Kim" in dieser Tabelle
erreicht haben?
</p>
<p>
    Der erste Reflex wäre nun das man per For-Schleife jede Zeile der Namens-Spalte per IF
    auf "Taylor" prüft und dann rechnet. Eigentlich möchte man aber nur mit den
    "Taylor" bzw. "Kim"  Zeilen Rechnen. Deshalb hier ein anderer Ansatz.
</p>
<h2>Schritt 3: Suchen per Application.Match</h2>

<p>
    Erstmal müssen die Daten der Namens-Spalte spnrName gesichert werden.
</p>

<iframe id=iframe2
    src="assets/txt/pageVBAwertefinden/2.txt" 

      
></iframe>

<p>
   Vereinfacht gesagt: Application.Match findet immer die erste Zelle mit 
   dem Suchbegriff in dem Suchbereich. Da die Namen "Taylor" und "Kim" mehrfach 
   vorkommen, würde immer nur die erste Zeile gefunden werden in der der Name steht.
   Damit die nächsten vorhanden Zeilen gefunden werden können, muss die Treffer-Zeile
   nach dem finden "verschwinden". Der Treffer wird eingefärbt. Zum Schutz vor 
   einer Endlos-Schleife, die man versehentlich einprogrammiert hat, wird bei 
   500 Treffern die Ausführung beendet.

</p>

<iframe id=iframe3
    src="assets/txt/pageVBAwertefinden/3.txt" 

        
></iframe>

<p>Hier wurden nur Taylor und Kim berechnet, um weitere zu berechen müssen die 
    Arrays: Suchbegriffe und Farben erweitert werden.
</p>
<h2>Ende</h2>
<br>
<h2> Application.Match() häufige (flüchtigskeit)Fehler bei der Verwendung</h2>
<p>
  Wenn Sie den Code von oben für Ihre Tabelle anpasen, könnten Sie ggf. auf dieses 
  Problem stoßen, irgendwann ist es aber soweit:
</p>
<p> Application.Match() findet Begriffe nicht obwohl diese richtig geschrieben sind.
</p>
<p>
    Hier das Ranking vielleicht hilft es:
</p>
<p>1. Platz 1: vergleiche ich wirklich String und String oder Zahl und String?</p>
<p>2. Enthält die Zelle die ich suche vorweg Leerzeichen oder ähnliches?</p>
<p>3. Ist auf der TabellenSeite die Zellenformatierung so wie erwartet? (siehe 1.)</p>
<p>4. Habe ich vll. Option Explicit nicht verwendet und mir ähnlich aussehende
     Variablen erstellt? </p>
<p>5. Case sensitivity beachtet?</p>     

<br>
