import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-page-vbasortieren',
  templateUrl: './page-vbasortieren.component.html',
  styleUrls: ['./page-vbasortieren.component.scss']
})
export class PageVBAsortierenComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
