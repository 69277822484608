<br>
<h1>VBA(Excel) Button zum Makro starten erstellen.</h1>
<div class="spaceH1"></div>
<h2>Schritt 1: Button einfügen</h2>

<p>Zum EntwicklertoolsMenü navigieren. Einfügen anklicken und dann dort
    "Schaltfläche" auswählen. </p>

<img class="img_VBA" src="assets/images/pageVBAbutton/buttoneinfugen.png" alt="assets/images/pageVBAbutton/buttoneinfugen.png">

<p>Nun hat sich der Mauszeiger verändert und es kann ein Rechteck gezeichnet werden.
   Nach dem Zeichen des Rechteckes öffnet sich das Fenster Makro zuweisen. Ein vorhandenes
   Makro kann zugewiesen werden.  
</p>

<img class="img_VBA" src="assets/images/pageVBAbutton/schaltflache.png" alt="assets/images/pageVBAbutton/schaltflache.png">

<h2>Schritt 2: Button Makro zuweisen</h2>

<p>Rechts Klick auf die Schaltfläche und Makro zuweisen auswählen.</p>

<img class="img_VBA" src="assets/images/pageVBAbutton/zuweisen.png" alt="assets/images/pageVBAbutton/zuweisen.png">

<p>Es öffnet sich erneut das Fenster Makro zuweisen. Ein vorhandenes Makro kann zugewiesen.
 werden.  </p>

 <img class="img_VBA" src="assets/images/pageVBAbutton/zuweisen2.png" alt="assets/images/pageVBAbutton/zuweisen2.png">

 <p>Ende.</p>
