<h1>Datenbanktest items</h1>
<div class=" spaceH1"></div>
<table>
    <tr>
      <th>ID</th>
      <th>NAME</th>
      
    </tr>
    <!-- data ist die variable in .ts! -->
    <tr *ngFor="let mydata of data[0]">
      <td>{{mydata.id}}</td>
      <td>{{mydata.name}}</td>
    </tr>
</table>
