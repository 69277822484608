<br>
<h1>Spalten kopieren</h1>
<div class="spaceH1"></div>

<p>
    Um Spalte1 aus Tabelle1 nach Tabelle2 Spalte1 ab Zeile1 zu kopiern:
</p>

<iframe id=iframe1
    src="assets/txt/pageVBASpaltenkopieren/1.txt" 

  
></iframe>


<h2>Spalten kopieren Beispiel:</h2>

<p>Sie haben eine Excel-Tabelle mit vielen Spalten und möchten einige
    davon in eine andere Tabelle der selben Arbeitsmappe kopieren?
</p>

<h2>Schritt 1: Leere Arbeitsmappe erstellen (*.xlsm) und ein Modul einfügen</h2>
<p>Hinweis: <a routerLink="/VBAintro" > VBA Editor Wie? und Wo? </a> </p>
 
<p style="color:darkred;">
    Ihre Arbeitsmappe muss zwingend 2 Tabellen-Blätter enthalten! z.B.: Tabelle1 und Tabelle2! 
</p>

    


<h2>Beispiel-Tabelle mit erfundenen Daten.</h2>

<object data="assets/tabellen/Demostatistik.html" type="text/html"


     style=" height:500px"

></object>

<h2>Schritt 2: Spalten finden</h2>  
<p>
    Sub anlegen.
    Als Basis wird hier <a routerLink="/Spaltenfinden"> Spalten finden</a> verwendet.
    Der Sub "Name" wir aber der Ordnung halber in spaltensuchenundkopieren geändert. 
</p>

<iframe id=iframe2
    src="assets/txt/pageVBASpaltenkopieren/2.txt" 

         
></iframe>

<h2>Schritt 3: Spalten kopieren</h2>

<p>Nachdem nun alle Spalten im Code verfügbar sind wird jede einzele Spalte 
    die in dem Array SpaltenzumKopieren eingetragen wurde kopiert. 
</p>

<iframe id=iframe3
    src="assets/txt/pageVBASpaltenkopieren/3.txt" 

     
></iframe>

<h2>Ende</h2>
