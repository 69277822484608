import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators, NgForm,FormGroupDirective } from '@angular/forms';
import { first } from 'rxjs/operators';
import { Router } from '@angular/router';
import { DataserviceService } from '../dataservice.service';
import {ErrorStateMatcher} from '@angular/material/core';

export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const invalidCtrl = !!(control && control.invalid && control.parent.dirty);
    const invalidParent = !!(control && control.parent && control.parent.invalid && control.parent.dirty);

    return (invalidCtrl || invalidParent);
  }
}

@Component({
  selector: 'app-changepassword',
  templateUrl: './changepassword.component.html',
  styleUrls: ['./changepassword.component.css']
})


export class ChangepasswordComponent implements OnInit {
  angForm: FormGroup;
  
  matcher = new MyErrorStateMatcher();
  
  constructor(private fb: FormBuilder,private dataService: DataserviceService,private router:Router) {
    
    this.angForm = this.fb.group({
      password: ['', Validators.required],
      newpassword: ['', Validators.required],
      confirmPassword: ['', [Validators.required]]
      
 
    }, {
      validators: this.checkPasswords
    });
   
  }
  
  checkPasswords(group: FormGroup) { // here we have the 'passwords' group
    let pass = group.controls.newpassword.value;
    let confirmPass = group.controls.confirmPassword.value;

    return pass === confirmPass ? null : { notSame: true }
  }




  ngOnInit(): void {
    
    this.checkloggedintoken();
  
  }

  checkloggedintoken(){
    
    if(this.dataService.getToken()){
      
      console.log("token: loggedIN");
      const loggedUserName = this.dataService.getToken();
      console.log("loggedUser: " + loggedUserName);

    }else{

      console.log("No token: NOT loggedIN");      
      

    }
  

  }
  //Call
  userchangepassword(angForm1)
  {
    const username = this.dataService.getToken();
    
    if(!username){

      console.log("Passwort ändern aber leider nicht eingelogt!");
      alert('Bitte vorher in "/Login" einlogen');
      return;

    }
    this.dataService.userchangepassword(username,angForm1.value.password,angForm1.value.newpassword)
      .pipe(first())
      .subscribe(
          //antwort:
          data => {
                if(!data){
                  console.log(data);
                  alert("Passwort falsch, Passwort wird nicht geändert!");

                }else{

                  alert("Passwort erfolgreich geändert");
                  const redirect = this.dataService.redirectUrl ? this.dataService.redirectUrl : '/dashboard';
                  this.router.navigate([redirect]);
                }
          
            },
          error => {
              alert("Error")
          });
  }
  
  get password() { return this.angForm.get('password'); }




}  