
<h2 class="text-center">Login</h2>
       
<form [formGroup]="angForm" (ngSubmit)="postdata(angForm)" autocomplete="off" >
    
    <div class="field">
    <span for="email">EmailAddresse:</span>
    <br>
    <input type="email" name="email" formControlName="email" autocomplete="off" class="form-control input-sm" placeholder="EmailAddresse">
    </div>
    
    <br>

    <div class ="field">
    <span for="Password">Passwort: </span>
    <br>
    <input type="password" name="Password" formControlName="password"  autocomplete="off" class="form-control input-sm" placeholder="Passwort">
    </div>
    
    <br>

    <button type="submit" class="btn btn-primary" [disabled]="!angForm.valid">Login</button>

</form>        
 
<br>
<br>
<br>       
<br>
<br>
<br>
<br>
<br>
<br>
<br>


    
 
