import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule,Directive,Input,Output,EventEmitter } from '@angular/core';

import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from "@angular/material/form-field";
import{ MatIconModule } from "@angular/material/icon";
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';

import { DragDropModule } from '@angular/cdk/drag-drop';
import {MatButtonModule } from '@angular/material/button';
import { HttpClientModule } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { InfoComponent } from './info/info.component';
import { NavbarComponent } from './navbar/navbar.component';
import { RectComponent } from './rect/rect.component';
import { HomeComponent } from './home/home.component';
import { AppRoutingModule } from './app-routing.module';//OF routing ??
import { RouterModule, Routes } from '@angular/router';//OF routing
import { HashLocationStrategy, LocationStrategy, PathLocationStrategy } from '@angular/common';//OF add # instead of 404error
import { PageVBAintroComponent } from './page-vbaintro/page-vbaintro.component';
import { PageVBAbuttonComponent } from './page-vbabutton/page-vbabutton.component';
import { PageVBAcopyTOComponent } from './page-vbacopy-to/page-vbacopy-to.component';
import { PageDatenschutzComponent } from './page-datenschutz/page-datenschutz.component';
import { FooterComponent } from './footer/footer.component';
import { PageCookiesComponent } from './page-cookies/page-cookies.component';
import { CookiepopupComponent } from './cookiepopup/cookiepopup.component';
import { PageSpaltenfindenComponent } from './page-spaltenfinden/page-spaltenfinden.component';
import { PageVBAkommentarComponent } from './page-vbakommentar/page-vbakommentar.component';
import { PageVBAWertefindenComponent } from './page-vbawertefinden/page-vbawertefinden.component';
import { PageVBASpaltenkopierenComponent } from './page-vbaspaltenkopieren/page-vbaspaltenkopieren.component';
import { PageVBAsortierenComponent } from './page-vbasortieren/page-vbasortieren.component';
import { PageVBAentfernenComponent } from './page-vbaentfernen/page-vbaentfernen.component';
import { TESTComponent } from './test/test.component';
import { PageBLOCKZComponent } from './page-blockz/page-blockz.component';
import { PageXmlViewComponent } from './page-xml-view/page-xml-view.component';
import { PageCdkDragComponent } from './page-cdk-drag/page-cdk-drag.component';
import { LoginComponent } from './login/login.component';
import { RegistrationComponent } from './registration/registration.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ChangepasswordComponent } from './changepassword/changepassword.component';
import { MylistComponent } from './mylist/mylist.component';
import { DemolistComponent } from './demolist/demolist.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { WebPlanComponent } from './web-plan/web-plan.component';// OF reload after routing


//!!!!!!!!!!!!!!!!!! r o u t i ng !!!!!!!
const routes: Routes = [  
{ path: 'home', component: HomeComponent },
{ path: 'VBAintro', component: PageVBAintroComponent ,runGuardsAndResolvers: 'always'},
{ path: 'VBAbutton', component: PageVBAbuttonComponent ,runGuardsAndResolvers: 'always'},
{ path: 'VBAcopyTO', component: PageVBAcopyTOComponent ,runGuardsAndResolvers: 'always'},
{ path: 'entfernen', component: PageVBAentfernenComponent ,runGuardsAndResolvers: 'always'},
{ path: 'VBAkommentar', component: PageVBAkommentarComponent ,runGuardsAndResolvers: 'always'},
{ path: 'VBAWertefinden', component: PageVBAWertefindenComponent ,runGuardsAndResolvers: 'always'},
{ path: 'Spaltenfinden', component: PageSpaltenfindenComponent ,runGuardsAndResolvers: 'always' },
{ path: 'Spaltenkopieren', component: PageVBASpaltenkopierenComponent ,runGuardsAndResolvers: 'always'},
{ path: 'sortieren', component: PageVBAsortierenComponent ,runGuardsAndResolvers: 'always'},
{ path: 'Datenschutz', component: PageDatenschutzComponent ,runGuardsAndResolvers: 'always'},
{ path: 'cookies', component: PageCookiesComponent},
{ path: 'XmlView', component: PageXmlViewComponent},
{ path: 'blockz', component: PageBLOCKZComponent},
{ path: 'mobilelist', component: PageCdkDragComponent},
{ path: 'test', component: TESTComponent},
{ path: 'login', component: LoginComponent},
{ path: 'registration', component: RegistrationComponent},
{ path: 'dashboard', component: DashboardComponent},
{ path: 'changepassword', component: ChangepasswordComponent},
{ path: 'mylist', component: MylistComponent}, //NICHT VERWENDEN ist classic DRAG  also nicht mobile fähig!!!!
{ path: 'demolist', component: DemolistComponent },
{ path: 'webplan', component: WebPlanComponent },



{ path: '', redirectTo: '/home', pathMatch: 'full' }
//lol nein!!!!
//{ path: 'reload', children: [{ path: '**', component: ReloadpageComponent  }]}, //OF reloadpage after routing


]



@NgModule({

  declarations: [
    AppComponent,
    HeaderComponent,
    InfoComponent,
    NavbarComponent,
    RectComponent,
    HomeComponent,
    PageVBAintroComponent,
    PageVBAbuttonComponent,
    PageVBAcopyTOComponent,
    PageDatenschutzComponent,
    FooterComponent,
    PageCookiesComponent,
    CookiepopupComponent,
    PageSpaltenfindenComponent,
    PageVBAkommentarComponent,
    PageVBAWertefindenComponent,
    PageVBASpaltenkopierenComponent,
    PageVBAsortierenComponent,
    PageVBAentfernenComponent,
    TESTComponent,
    PageBLOCKZComponent,
    PageXmlViewComponent,
    PageCdkDragComponent,
    LoginComponent,
    RegistrationComponent,
    DashboardComponent,
    ChangepasswordComponent,
    MylistComponent, //NICHT VERWENDEN ist classic DRAG  also nicht mobile fähig!!!!
    DemolistComponent, SidebarComponent, WebPlanComponent
     
    
    
  ],

  imports: [
    BrowserModule,
    DragDropModule,
    HttpClientModule, 
    FormsModule,
    
    MatFormFieldModule,
    MatInputModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatIconModule,

    AppRoutingModule, //OF routing ??
    RouterModule.forRoot(routes), //OF routing
    RouterModule.forRoot(routes, {scrollPositionRestoration:'top'}),// to Top OF
    RouterModule.forRoot(routes, {onSameUrlNavigation: 'reload'}), //OF 11 05 reload
  ],

  exports:[
    RouterModule
    
    

  ],


  providers: [{ provide: LocationStrategy, useClass: HashLocationStrategy }],   //OF add '#' instead of 404error
  bootstrap: [AppComponent]
})
export class AppModule {
  

}

