
<!-- "Gerüst" für alle Seiten -->
    
    <app-header></app-header>
       
    
    
    <app-navbar></app-navbar> 
    <br>
    <br>
    <br>
    <br>

    
    

    <button style="position: fixed;" type="button" *ngIf="logoutbtn" class="btn btn-danger btn-block" (click)="logout()">logout</button>
    

  

    

    <!--sidebar->left,  spacer,   outlet("pages")-> right(left+++) -->
    
    <div id="sidebar_left_content_right">



      <div id="sidebar_left" style="position:fixed; float:left; top:110px; z-index:1">

        <app-sidebar></app-sidebar>

      </div>


      <div id="content_frame" style="margin-left:200px;">

        <!-- Inhalt wird durch Routes verknüpft: Ziele hier geladen -->
        <router-outlet></router-outlet>

      </div>

      

    </div>
  

    

    
    <app-cookiepopup></app-cookiepopup>
    <app-footer></app-footer>


 




  
