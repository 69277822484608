<br>
<h1>Zeilen und Spalten entfernen</h1>
<div class="spaceH1"></div>
<br>

<p>
 Um Spalten und Zeilen zu leeren oder eine Spalte zu löschen:  
</p>

<iframe id=iframe1
    src="assets/txt/pageVBAentfernen/1.txt" 
    
     
></iframe>

<p style="color:darkred;">
    Sie sollten niemals statisch löschen! (ThisWorkbook.Worksheets(1).Columns(1).Delete) 
    Es würde jedesmal die erste Spalte gelöscht werden, da an die gelöschte Spalte1 dann Spalte2
    tritt und zur neuen Spalte1 wird. 
</p>

<h2> Entfernen Beispiel</h2>

<p>Sie möchten eine Spalte oder Zeilen entfernen?</p>

<h2>Schritt 1: Leere Arbeitsmappe erstellen (*.xlsm) und ein Modul einfügen</h2>
<p>Hinweis: <a routerLink="/VBAintro" > VBA Editor Wie? und Wo? </a> </p>
 

<h2>Beispiel-Tabelle mit erfundenen Daten.</h2>

<object data="assets/tabellen/Demostatistik.html" type="text/html"

  

></object>

<h2>Schritt 2: Spalten finden und löschen</h2>  
<p>
    Eine modifizierte Version von  <a routerLink="/Spaltenfinden"> Spalten finden</a> 
    wird verwendet: Bevor die Spalten den Variablen zugewiesen werden, wird dieses
    mal die Spalte ID gesucht und gelöscht. Sie sollten nur Spalten löschen deren 
    Existenz Sie vorher geprüft haben! Wenn Sie nur .Columns(1).Delete eingeben wird 
    bei jedem Ausführen die Erste Spalte gelöscht! 
</p>

<iframe id=iframe2
    src="assets/txt/pageVBAentfernen/2.txt" 

        

    
></iframe>


<h2>Schritt 3: Zeilen suchen und entfernen</h2>

<p>
    Sie möchten nun die Zeilen mit "Küche" und "Schreibtisch" entfernen?
    Es wird dafür das Array Suchbegriffe angelegt. Eine For-Schleife um die 
    Begriffe durch zu schalten und ein Loop zum ausführen des suchens. 
    Der Loop wird mit iLoop gezählt und auf 500 limitiert(optional).
    Die Suchbegriffe werden per Application.Match in der Spalte "Name" 
    (spnrName) gesucht. Die gesamte Zeile wird nicht gelöscht sondern 
    geleert .Value ="" dadurch bleibt die Formatierung erhalten.

</p>
<iframe id=iframe3
    src="assets/txt/pageVBAentfernen/3.txt" 

        
></iframe>

<br>
<p>
    Nun möchten Sie die Tabelle  <a routerLink="/sortieren" > sortieren? </a> </p>

<h2>Ende.</h2>
