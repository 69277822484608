<br>
<H1> Webplan</H1>
<div class="spaceH1"></div>





<div id="row" class="row">


  <div id="überschrift" class="überschrift">

    <div class="überschrift_item">  Pool </div>
    <div class="überschrift_item ue1">	1   </div>
    <div class="überschrift_item ue2">  2   </div>
    <div class="überschrift_item ue3">  3   </div>

  </div>





  <!--  #....xyz...nameofthis.cdkDropList ="cdkDropList"   used to link/connect Lists-->
  <div id="dropPool" class="stay_there "
       cdkDropList
       #dl_pool="cdkDropList"
       [cdkDropListData]="data_dl_pool[0]"
       [cdkDropListConnectedTo]="[dl_s1,dl_s2,dl_s3]"
       (cdkDropListDropped)="drop($event)">

    <div id="hardcoded_pool">Pool Ablage</div>

    <!--loop  this.items -->
    <div class="item"
         *ngFor="let item of data_dl_pool[0] "
         cdkDrag
         id={{item.id}} name={{item.name}}>


      <div>Id:  {{item.id}} Name:  {{item.name}} </div>




    </div>

  </div>

  <!--<div class="rowcolumns">-->

  <div id="drop_s1"
       class="column col1"
       cdkDropList
       #dl_s1="cdkDropList"
       [cdkDropListData]="data_dl_s1[0]"
       [cdkDropListConnectedTo]="[dl_pool,dl_s2,dl_s3]"
       (cdkDropListDropped)="drop($event)">

    <!--loop  this.items -->
    <div class="item"
         *ngFor="let item of data_dl_s1[0] "
         cdkDrag
         id={{item.id}} name={{item.name}}>


      <div>Id:  {{item.id}} Name:  {{item.name}} </div>




    </div>



  </div>

  <div id="zeitAchse1" class="zeitachse z1">  </div>

  <div id="drop_s2"
       class="column col2"
       cdkDropList
       #dl_s2="cdkDropList"
       [cdkDropListData]="data_dl_s2[0]"
       [cdkDropListConnectedTo]="[dl_s1,dl_pool,dl_s3]"
       (cdkDropListDropped)="drop($event)">

    <!--loop  this.items -->
    <div class="item"
         *ngFor="let item of data_dl_s2[0] "
         cdkDrag
         id={{item.id}} name={{item.name}}>


      <div>Id:  {{item.id}} Name:  {{item.name}} </div>




    </div>



  </div>


  <div id="zeitAchse2" class="zeitachse z2">   </div>

  <div id="drop_s3"
       class="column col3"
       cdkDropList
       #dl_s3="cdkDropList"
       [cdkDropListData]="data_dl_s3[0]"
       [cdkDropListConnectedTo]="[dl_s1,dl_s2,dl_pool]"
       (cdkDropListDropped)="drop($event)">

    <!--loop  this.items -->
    <div class="item"
         *ngFor="let item of data_dl_s3[0] "
         cdkDrag
         id={{item.id}} name={{item.name}}>


      <div>Id:  {{item.id}} Name:  {{item.name}} </div>




    </div>


  </div>

  <div id="zeitAchse3" class="zeitachse z3"> 	 </div>


  <!--</div>-->


</div>
