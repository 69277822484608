import { Component,OnInit,AfterContentInit, AfterViewInit } from '@angular/core';




@Component({
  selector: 'app-page-spaltenfinden',
  templateUrl: './page-spaltenfinden.component.html',
  styleUrls: ['./page-spaltenfinden.component.scss'],
  
  
})




export class PageSpaltenfindenComponent   {
  
  ngOnInit(): void {
        
     //console.log("spalten finden ngoninit")
     
  }

 
  
  


}
