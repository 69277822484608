import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http'; 
import * as xml2js from 'xml2js'; // LOL wasn Krampf XML

@Component({
  selector: 'app-page-xml-view',
  templateUrl: './page-xml-view.component.html',
  styleUrls: ['./page-xml-view.component.scss']
})
export class PageXmlViewComponent implements OnInit {

  public xmlItems: any;

  constructor(private _http: HttpClient) {

     // _http .loadXML();
     console.log("started: loadXML")
     
     this.loadXML_HTTP('/assets/xml/test.xml');


  }  
  

  ngOnInit(): void {
  
  
  }

  // XML from S E R V E R !
  // fix test.xml
  loadXML_HTTP(path) {  

    this._http.get(path, 
      {  
        headers: new HttpHeaders()  
          .set('Content-Type', 'text/xml')  
          .append('Access-Control-Allow-Methods', 'GET')  
          .append('Access-Control-Allow-Origin', '*')  
          .append('Access-Control-Allow-Headers', "Access-Control-Allow-Headers, Access-Control-Allow-Origin, Access-Control-Request-Method"),  
        responseType: 'text'  
      })  
      .subscribe((data) => {  
        this.parseXML_HTTP(data)  
          .then((data) => {  
            this.xmlItems = data;  
          });  
      });  
  }  
  parseXML_HTTP(data) {  
    return new Promise(resolve => {  
      var k: string | number,  
        arr = [],  
        parser = new xml2js.Parser(  
          {  
            trim: true,  
            explicitArray: true  
          });  
      parser.parseString(data, function (err, result) {  
        var obj = result.Daten;  
        for (k in obj.item) {  
          var item = obj.item[k];  
          arr.push({  
            id: item.id[0],  
            dauer: item.dauer[0],  
            
          });  
        }  
        resolve(arr);  
      });  
    });  
  }  


  //button import XML
  // OPEN_FILE_DIALOG   + ONCHANGE.EVENT => Read && Parse  
  importData() {
    let input = document.createElement('input');
    input.type = 'file';
    input.onchange = _ => {
      // you can use this method to get file and perform respective operations
              let files =   Array.from(input.files);
              console.log(files);

              this.doXMLreadParse(files);
          
          
            };
    input.click();
    
  }
  // button import XML
  // doXML + read && Parse
  doXMLreadParse(files){
    console.log("doXML started")
    console.log("doXML files: "+ files[0].name)
    
    this.readFile(event);
     
    
  }
  //button import XML
  //READ FILE from OpenFileDialog call Parse
  readFile(event) {
    var file = event.target.files[0];
    if (!file) return;
    var reader = new FileReader();
    

    reader.onload = function(event) {
     
      // hier: reader.result verfügbar:.
      parseXML(reader.result);
           

    }
       
    reader.readAsText(file);
    
  }

  

}  

//button import XML
//XML PARSER (button import) needs called from Reader
function parseXML(xmlSTR){
  var parser = new DOMParser();
      var xmlDoc = parser.parseFromString(xmlSTR,"text/xml");
  
      var x = xmlDoc.getElementsByTagName("item");
      
      console.log(xmlDoc.documentElement);
      console.log(x);
    
      changeXMLtable(xmlDoc);
}


//button import XML  change table
function changeXMLtable(xmlDoc){
        
  console.log("ChangeXMLTable started")
    
  var xmlTable = document.getElementById("xmlTable");
    
      xmlTable.style.backgroundColor="beige";
      xmlTable.style.color="purple";
      
  console.log("Old Table: " + xmlTable.innerText);

  xmlTable.innerHTML="";
    
  console.log("Old Table deleted");

  var x = xmlDoc.getElementsByTagName("item");

  console.log('loaded xmlDoc: "item" length: '+ x.length);

  var table="<tr><th>ID</th><th>Dauer</th></tr>";

    for (var i = 0; i <x.length; i++) {
                 
      table += "<tr><td>" +
      x[i].getElementsByTagName("id")[0].childNodes[0].nodeValue +   "</td><td>" +
      x[i].getElementsByTagName("dauer")[0].childNodes[0].nodeValue +   "</td></tr>";
      
    }


    
  document.getElementById("xmlTable").innerHTML = table;
  
  console.log("New Table: " + xmlTable.innerText);
}