
// DEMO  ZU  page-cdk-drag aka "mobilelist"  modified 29.08.2021

// functions auskomentiert oder cut!!!!


import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, NgForm } from '@angular/forms';
import {CdkDrag, CdkDragDrop, moveItemInArray, transferArrayItem} from '@angular/cdk/drag-drop';
import { HttpClient } from '@angular/common/http';
import { DataserviceService } from '../dataservice.service';
import { Router } from '@angular/router';
import { first } from 'rxjs/operators';




@Component({
  selector: 'app-demolist',
  templateUrl: './demolist.component.html',
  styleUrls: ['./demolist.component.scss']
})

export class DemolistComponent implements OnInit {
  
  angForm: FormGroup;
  
  constructor(private fb: FormBuilder,private http: HttpClient, private dataService: DataserviceService, private router:Router) {
    
    this.angForm = this.fb.group({
 
     name: ['', [Validators.required,Validators.minLength(1)]],
     spalte: [''],
     dauer: [''],
     termin:[''],
     menge:[''] 
    });
   


  }

  ngOnInit(): void {
    
    this.getusersettings();

    this.getpooldata('pool',this.datapool);
    this.getpooldata('spalte1',this.datasp1);
    this.getpooldata('spalte2',this.datasp2);
    this.getpooldata('spalte3',this.datasp3);
    
    
  
  }

  //LIST
  datapool = [];
  datasp1 =[];
  datasp2 =[];
  datasp3 =[];
  delIDs=[];
  additemDATAs=[];

  viewDauer = 1;
  viewMenge = 1;
  viewTermin = 1;
  viewSpalte1 = 1;
  viewSpalte2 = 1;
  viewSpalte3 = 1;

  filterBTNcolorON = "lightgreen";
  filterBTNcolorOFF = "Beige"; 

  // DRAG  DROP 
  drop(event: CdkDragDrop<string[]>) {
    
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
      console.log("Drop: " + event.container.data, event.previousIndex, event.currentIndex);
    
    } else {

      transferArrayItem(event.previousContainer.data,
                        event.container.data,
                        event.previousIndex,
                        event.currentIndex);
      console.log("Drop: " + event.previousContainer.data,event.container.data,event.previousIndex,event.currentIndex); 
                        
    }


  }

  getusersettings(){
    // //BUTTONS
    // //check !User; check who;
    // const  username  = this.dataService.getToken();
    
    // if(!username){

    //   console.log("Um setting zu laden, bitte einloggen");
      
    //   var bannerMSG = document.getElementById('bannerMSG3');
    //   // Show Message:
    //       bannerMSG.classList.remove('hidden'); 

    //   return;

    // }
    this.dataService.getusersettings("demo")
        .pipe(first())
        .subscribe(
          data =>{
             
            //entpacken data[0]['   ']  :D
            
            //HTML  "items in box" locig *ngIF
            this.viewDauer = data[0]['showdauer'];
            this.viewMenge = data[0]['showmenge'];
            this.viewTermin = data[0]['showtermin'];
            
            
            // javascript class locig "Spalte" SET viewSpalte = 0 oder 1 aus DB
            this.viewSpalte1 = data[0]['showspalte1'];
            this.viewSpalte2 = data[0]['showspalte2'];
            this.viewSpalte3 = data[0]['showspalte3'];

                    
            if(this.viewSpalte1 == 1){

              document.getElementById('spalte1').classList.remove('hidden');
      
            }else{
                
              document.getElementById('spalte1').classList.add('hidden');
              
            }

            if(this.viewSpalte2 == 1){

              document.getElementById('spalte2').classList.remove('hidden');
      
            }else{
                
              document.getElementById('spalte2').classList.add('hidden');
              
            }

            if(this.viewSpalte3 == 1){

              document.getElementById('spalte3').classList.remove('hidden');
      
            }else{
                
              document.getElementById('spalte3').classList.add('hidden');
              
            }

            // Filter Buttons einfärben
            //function schreiben? 
            var switchesNames = [this.viewDauer,this.viewMenge,this.viewTermin,this.viewSpalte1,this.viewSpalte2,this.viewSpalte3];
            var btnIDs = ["btnDauer","btnMenge","btnTermin","btnspalte1","btnspalte2","btnspalte3"]; 
            
                       
            for (var i = 0; i < switchesNames.length; i++) {
                
              if(switchesNames[i] == 1){
                
                document.getElementById(btnIDs[i]).style.backgroundColor  = this.filterBTNcolorON;
              
              }else{
                
                document.getElementById(btnIDs[i]).style.backgroundColor = this.filterBTNcolorOFF;
              
              }
            
            }



            console.log(data[0]);
            
            console.log("getusersettings(username) finished");



          },
          error => {
              
            console.log("getusersettings(username) ERROR");
              
          });
        
  } 


  


  getpooldata(sqlspalte,datafor){ //with: this.dataService.userpooldata(username, sqlspalte)

    // //check !User; check who;
    // const  username  = this.dataService.getToken();
    
    // if(!username){

    //   console.log("Um Ihre Liste zu sehen, bitte einloggen");
      
    //   var bannerMSG = document.getElementById('bannerMSG1');
    //   // Show Message:
    //       bannerMSG.classList.remove('hidden'); 

    //   return;

    // }
      
    if(datafor ==this.datapool){
    
      this.dataService.userpooldata("demo", sqlspalte)
        .pipe(first())
        .subscribe(
          data =>{

            this.datapool.push(data);
                                  
          },
          error => {
              
            console.log(error);
              
          });
        
    } 
  
    if(datafor ==this.datasp1){
    
      this.dataService.userpooldata("demo", sqlspalte)
        .pipe(first())
        .subscribe(
          data =>{

            this.datasp1.push(data);

          },
          error => {
              
            console.log(error);
              
          });
        
    } 

    if(datafor ==this.datasp2){
    
      this.dataService.userpooldata("demo", sqlspalte)
        .pipe(first())
        .subscribe(
          data =>{

            this.datasp2.push(data);                  

          },
          error => {
              
            console.log(error);
              
          });
        
    } 

    if(datafor ==this.datasp3){
    
      this.dataService.userpooldata("demo", sqlspalte)
        .pipe(first())
        .subscribe(
          data =>{
                  
            this.datasp3.push(data);

          },
          error => {
              
            console.log(error);
              
          });
        
    } 




  }
 // ohne Insert in DB keine auto ID, ohne ID keine solide Zuordnung zum löschen, ändern...

  additem_to_DB(angForm1){
    
    //check ob eingelogged also token vorhanden
    // const  username  = this.dataService.getToken();
    
    // if(!username){

    //   console.log("Um Einträge hinzuzufügen bitte einloggen!");
      
    //   var bannerMSG = document.getElementById('bannerMSG2');
    //   // Show Message:
    //       bannerMSG.classList.remove('hidden');
    //   //this.router.navigate(['login']);    

    //   return;

    // }
    
    const spaltenarray = ["","pool", "spalte1", "spalte2","spalte3"];
    if(!spaltenarray.includes(angForm1.value.spalte)){
      console.log(angForm1.value.spalte);
      alert("Das Feld Spalte darf nur folgende einträge verwenden: "+ spaltenarray);

      return;
    }
    
    // Spalte und position ermitteln
    var spalte = angForm1.value.spalte;
    if(spalte ===""){
      
      angForm1.value.spalte = "pool";
      
      console.log("angForm1.value.spalte === was empty; now changed to: 'pool'!");
    } 

    var spalte = angForm1.value.spalte;

    switch(spalte){
          
      case "pool":   
      var position = this.datapool[0].length;  
                          
      break;
      case "spalte1":
      var position = this.datasp1[0].length; 

      break;
      case "spalte2":
        var position = this.datasp2[0].length; 
  
      break;
      case "spalte3":
        var position = this.datasp3[0].length; 
      
      break;

    }
  
  
    //Termin auf DB fähigkeit prüfen 1st empty
    var termin = angForm1.value.termin;
    
    if(termin ===""){
      


      angForm1.value.termin = "01.01.1901";
      

      console.log("angForm1.value.termin === was empty; now changed to: '01.01.1901'!");

    } 

    var insert ={
                     
           "name": angForm1.value.name,
           "position": position,
           "dauer": angForm1.value.dauer,
           "termin": angForm1.value.termin,
           "menge": angForm1.value.menge
         
    }

    switch(spalte){
        case "pool":   
                                     
        this.datapool[0].push(insert);
                                         
        break;
        case "spalte1":
                                       
        this.datasp1[0].push(insert);
                                      
        break;
        case "spalte2":
                      
        this.datasp2[0].push(insert);
                       
        break;
        case "spalte3":
                     
        this.datasp3[0].push(insert);
                       
        break;
  
    }
    
    // IN DB hinzufügen  als "NEU" kennzeichen in additemtoDB.php     // dataService Reihenfolge: spalte,position,name,dauer, termin, menge, username
    // this.dataService.additemtoDB(angForm1.value.spalte, position, angForm1.value.name, angForm1.value.dauer, angForm1.value.termin, angForm1.value.menge, username)
    //   .pipe(first())
    //   .subscribe(
    //       data => {
    //             console.log(data);
    //             //RETURN Daten vorallem die auto ID
    //             //Eintrag in Table hinzufügen 
    //             //mit den selben "INSERT"ed  to DB data[] .. aber nicht nochmal aus DB holen ;)
    //             var spalte = data['spalte'];
                
    //             var id = data['id'];
    //             var name = data['name'];
    //             var position = data['position'];
    //             var dauer = data['dauer'];
    //             var termin = data['termin'];
    //             var menge = data['menge'];
                
    //             var insert ={
    //               "id": id,
    //               "name": name,
    //               "position": position,
    //               "dauer": dauer,
    //               "termin": termin,
    //               "menge": menge
    //             }

    //             switch(spalte){
    //               case "pool":   
                                   
    //               this.datapool[0].push(insert);
                                       
    //               break;
    //               case "spalte1":
                                     
    //               this.datasp1[0].push(insert);
                                    
    //               break;
    //               case "spalte2":
                    
    //               this.datasp2[0].push(insert);
                     
    //               break;
    //               case "spalte3":
                   
    //               this.datasp3[0].push(insert);
                     
    //               break;

    //             }
             
    //          //item Daten in das additemDATAS schreiben zur übersicht / kontrolle
    //         this.additemDATAs.push({"name": angForm1.value.name, "spalte": angForm1.value.spalte, "position": position, "dauer": angForm1.value.dauer, "username": username});
    //         console.log(this.additemDATAs);   

          
    //       },
    //       error => {
    //           alert("Bitte mindestens einen Namen vergeben!");
    //       });
  

  
  }


// #speichern  1. löschen ausführen  2. Alle Spalten bzw Zeilen aktualisieren 3. 
//WICHTIG: erstellte neu items von Status "Neu" 1 auf 0 setzen!!! (also nach dem erstellen min einmal gespeichert) sonst werden diese beim "import" pool.php gelöscht!
save_changes_to_DB($event){
  
  //DEMO ALERT
  alert('Demo Version! \n \n Änderungen speichern nicht möglich!');
  
  // console.log("speichern to DB geklickt!");
  
  // const  username  = this.dataService.getToken();
  
  // if(!username){

  //   console.log("Um änderungen zu speichern einloggen!");
    
  //   var bannerMSG = document.getElementById('bannerMSG1');
    
  //       bannerMSG.classList.remove('hidden');   

  //   return;

  // }
  

  // //löschen der delIDs aus DB
  // if(this.delIDs.length >0){
    
  //   this.dataService.delete_from_DB(username,this.delIDs)
  //      .pipe(first())
  //      .subscribe(
  //          data => {
          
  //            console.log("gelöscht");
  //          },
  //          error => {
  //           alert("ERROR delete_from_DB");
  //       }); 
               
  // } 

  // // atm Positionen aus den Spalten/table in die data...[0] schreiben
  // var arrayDATAs=[this.datapool[0], this.datasp1[0], this.datasp2[0], this.datasp3[0]];

  // for(var data0 of arrayDATAs){

  //   for( var item of data0){
                  
  //       var atm_array_position = data0.indexOf(item);
                  
  //       item["position"] = atm_array_position;
    
  //   }
  
  // }  
  

  // this.dataService.save_changes_to_DB(username,this.datapool[0],this.datasp1[0],this.datasp2[0],this.datasp3[0])
  //   .pipe(first())
  //   .subscribe(
  //       data => {
        
  //         console.log("Update Status: OK");

  //       });

  // console.log("Update done.");        

}


// ID wird in this.delIDs.push  beim speichenr/savechanges  ausgeführt
delete_item($event){
  console.log("löschen geklickt");  
  console.log($event);
  
  // check USER
  // const  username  = this.dataService.getToken();
  
  // if(!username){

  //   console.log("Um zu löschen bitte einloggen!");
    
  //   var bannerMSG = document.getElementById('bannerMSG1');
    
  //       bannerMSG.classList.remove('hidden');   

  //   return;

  // }
 

  var delSpalteID = $event.target.parentNode.parentNode.id;
  console.log(delSpalteID);
  
  // check current index in datapool[0]
  var delROW = $event.target.parentNode.id;
  console.log(delROW);  

  var delarrayposDATA;
  var spalte;
  var datatable;
  switch(delSpalteID){
    case "pool":
      spalte ="pool";   
      delarrayposDATA = this.datapool[0][delROW];
      datatable = this.datapool[0];                            
    break;
    case "spalte1":
      spalte = "spalte1";
      delarrayposDATA = this.datasp1[0][delROW];
      datatable = this.datasp1[0];   
    break;
    case "spalte2":
      spalte = "spalte2";
      delarrayposDATA = this.datasp2[0][delROW]; 
      datatable = this.datasp2[0];     
    break;
    case "spalte3":
      spalte = "spalte3";
      delarrayposDATA = this.datasp3[0][delROW]; 
      datatable = this.datasp3[0];
    break;

  }
  console.log(spalte);
  console.log(delarrayposDATA['id']);
  console.log(delarrayposDATA );
  
  //Confirm delete
        
  if (confirm("Soll: " + delarrayposDATA["name"] + " gelöscht werden?" )) {
       
    //ID in delIDs schreiben; 
    //später beim "speichern / updaten löschen"
    this.delIDs.push(delarrayposDATA['id']);
    console.log(this.delIDs);
       
    //aus table/data[] löschen
    datatable.splice(delROW,1); 
      
  }        

  
}

toogle_Dauer(){
    
  if(this.viewDauer == 0){
    
    this.viewDauer = 1; 
    
    document.getElementById("btnDauer").style.backgroundColor = this.filterBTNcolorON;

  }else{
    
    this.viewDauer = 0;
    
    document.getElementById("btnDauer").style.backgroundColor = this.filterBTNcolorOFF;
  }
  
  //console.log(this.viewDauer); 
  
}

toogle_Menge(){
  
  if(this.viewMenge == 0){
    
    this.viewMenge = 1; 
  
    document.getElementById("btnMenge").style.backgroundColor =this.filterBTNcolorON;

  }else{
    
    this.viewMenge = 0;
    
    document.getElementById("btnMenge").style.backgroundColor  = this.filterBTNcolorOFF;

  }
  
  //console.log(this.viewMenge); 
  
}

toogle_Termin(){
  
  if(this.viewTermin == 0){
    
    this.viewTermin = 1; 
    
    document.getElementById("btnTermin").style.backgroundColor = this.filterBTNcolorON;

  }else{
    
    this.viewTermin = 0;
    
    document.getElementById("btnTermin").style.backgroundColor  = this.filterBTNcolorOFF;

  }
  
  //console.log(this.viewTermin); 
  
}

toogle_spalte(spalte){
  var spa = document.getElementById(spalte);
  
  switch(spalte){

    case 'spalte1':
     if(this.viewSpalte1 == 1){
       this.viewSpalte1 = 0;
       document.getElementById("btnspalte1").style.backgroundColor = this.filterBTNcolorOFF;
      }else{
       this.viewSpalte1 = 1;
       document.getElementById("btnspalte1").style.backgroundColor = this.filterBTNcolorON; 
      }
     console.log("viewSpalte1: " + this.viewSpalte1);
    break;
     
    case 'spalte2':
      if(this.viewSpalte2 == 1){
        this.viewSpalte2 = 0;
        document.getElementById("btnspalte2").style.backgroundColor = this.filterBTNcolorOFF;
      }else{
        this.viewSpalte2 = 1;
        document.getElementById("btnspalte2").style.backgroundColor = this.filterBTNcolorON;
      }
      console.log("viewSpalte2: " +this.viewSpalte2);
    break;
    
    case 'spalte3':
      if(this.viewSpalte3 == 1){
        this.viewSpalte3 = 0;
        document.getElementById("btnspalte3").style.backgroundColor = this.filterBTNcolorOFF;
      }else{
        this.viewSpalte3 = 1;
        document.getElementById("btnspalte3").style.backgroundColor = this.filterBTNcolorON;
      } 
      console.log("viewSpalte3: " + this.viewSpalte3);
    break;
      
  }
  
  if(spa.classList.contains('hidden')){

      spa.classList.remove('hidden');

  }else{
      
    spa.classList.add('hidden');
    
  }
 
  console.log(spa);

}

  save_buttons_status_to_DB(){
    //DEMO A L E R T 
    alert('Demo Version! \n \n Einstellungen speichern nicht möglich');

    // console.log("save_buttons_status_to_DB");
    
    // const  username  = this.dataService.getToken();
  
    // if(!username){

    //   console.log("Um einstellungen zu speichern bitte einloggen!");
      
    //   var bannerMSG = document.getElementById('bannerMSG3');
      
    //       bannerMSG.classList.remove('hidden');   

    //   return;

    // }
    // //in DB schreiben
    // this.dataService.save_buttons_status_to_DB(username,this.viewDauer,this.viewMenge,this.viewTermin,this.viewSpalte1,this.viewSpalte2,this.viewSpalte3)
    //    .pipe(first())
    //    .subscribe(
    //        data => {
          
    //          console.log("save_buttons_status_to_DB()   finished!");
    //        },
    //        error => {
    //         alert("ERROR save_buttons_status_to_DB()");
    //     }); 

  
  }







////// R  E   S   T   E  mal schauen ob verwendbar

// OPEN_FILE_DIALOG   + ONCHANGE.EVENT => Read && Parse  
importData() {
  let input = document.createElement('input');
  input.type = 'file';
  input.onchange = _ => {
    // you can use this method to get file and perform respective operations
            let files =   Array.from(input.files);
            console.log(files);

            this.doXMLreadParse(files);
        
        
          };
  input.click();
  
}

// doXML + read && Parse
doXMLreadParse(files){
  console.log("doXML started")
  console.log("doXML files: "+ files[0].name)
  
  this.readFile(event);
         
  
}

//READ FILE from OpenFileDialog call Parse
readFile(event) {
  var file = event.target.files[0];
  if (!file) return;
  var reader = new FileReader();
  

  reader.onload = function(event) {
   
    // hier: reader.result verfügbar:.
    parseXML(reader.result);
    
  }
     
  reader.readAsText(file);
  
}


}  


//XML PARSER needs called from Reader
function parseXML(xmlSTR){
var parser = new DOMParser();
    var xmlDoc = parser.parseFromString(xmlSTR,"text/xml");

    var x = xmlDoc.getElementsByTagName("item");
    
    console.log(xmlDoc.documentElement);
    console.log(x);
  
   
   

}



