   

<br>
<h1>Excel-Datei öffnen und Inhalt kopieren </h1>
<div class="spaceH1"></div>
<p>
Sie bekommen regelmäßig eine Excel-Datei und fügen dort Formeln ein? 
oder Kopieren Spalten in eine andere Datei? Um etwas zu berechnen?
<br>
Kann man das auch einfacher haben?
<br>
Vielleich mit einem Makro!
</p>
<p style="color: red;">Durch den folgenden Code werden alle bereits vorhanden Daten in
    der "Import Ziel-Tabelle" importFile_TO("...") gelöscht, da vorher nicht die Zeilen und Spalten
     längen der Import-Datei-Tabelle ermittelt werden. Es wird in das nun leere 
     Tabellenblatt eingefügt!

</p>
<h2>Schritt 1: Leere Arbeitsmappe erstellen (*.xlsm) und ein Modul einfügen</h2>
<p>Hinweis: <a routerLink="/VBAintro" > VBA Editor Wie? und Wo? </a> </p>
 
<h2>Schritt 2: Sub erstellen</h2>  

<p>Sub mit dem Namen import anlegen. Hier wird später das Sub importFile_TO
    aufgerrufen. Code folgt am Ende!
</p>


<iframe id=iframe1
    src="assets/txt/pageVBAcopyto/1.txt" 

     
></iframe>


<p>darunter im selben Fenster ein weiteres Sub mit dem Namen importFile_TO. 
    In die Klammern 3 zusätzliche Parameter definieren. Darunter 2 Objekte für 
    die Arbeitsblätter und 1 String für den Pfad. </p>

<iframe id=iframe2
    src="assets/txt/pageVBAcopyto/2.txt" 

     
></iframe>

<p style="color: red;">In der Tabelle in die später kopiert werden soll: Pauschal vorher alle Spalten 
    leeren, die formatierungen bleiben aber erhalten. 

</p>

<iframe id=iframe3
    src="assets/txt/pageVBAcopyto/3.txt" 

    
></iframe>

<p> File_path das Ergebnis des OpenFileDialog(Filter:(*.xlsx; *.xlsm)) aufrufs zuweisen.
     z.B. C:\Users\...\Desktop\Statistik.xlsx</p>

     <iframe id=iframe4
     src="assets/txt/pageVBAcopyto/4.txt" 

     
 ></iframe>

<p> Wenn File_path keinen Inhalt hat dann MsgBox "keine Datei" und Sub beenden
    ansonsten die Arbeitsmappe die in File_path hinterlegt ist öffnen.
</p>

<iframe id=iframe5
    src="assets/txt/pageVBAcopyto/5.txt" 

    
></iframe>

<p>objFrom die Tabelle(1) der eben geöffneten Arbeitsmappe zuweisen,
   objTo erhält den Platzhalter für die Tabelle(TO_worksheetname) in dieser Arbeitsmappe.

</p>

<iframe id=iframe6
    src="assets/txt/pageVBAcopyto/6.txt" 

  
></iframe>

<p>Nun den Gesamten Inhalt von objFrom kopieren und in objTo ab der Zeile
    (To_cell, To_column) einfügen. Das ActiveWorkbook schließen. Die Beiden Objekte leeren.
    Sub beenden.
</p>

<iframe id=iframe7
    src="assets/txt/pageVBAcopyto/7.txt" 

  
></iframe>

<p>Fast fertig. Ganz am Anfang ganz Oben wurde Sub import() angelegt das bisher nur einen Kommentar beinhaltete.
    Dieses dient nun zum aufrufen von importFile_TO() mit 3 Pflicht Parametern(TabellenName, Zeile, SpalteZahl). 
</p>
<p> Einfügen in Sub Import() in der Mitte: importFile_TO "Tabelle1", 1, 1</p>

<iframe id=iframe8
    src="assets/txt/pageVBAcopyto/8.txt" 

   
></iframe>

<p>Nun sollte das Modul ungefähr so aussehen:</p>

<iframe id=iframe9
    src="assets/txt/pageVBAcopyto/9.txt" 

    
></iframe>

<p>bzw. so:</p>

<img class="img_VBA" src="assets/images/pageVBAcopyto/copyTO.png" alt="assets/images/pageVBAcopyto/copyTO.png">


<p>Nun kann import() einem Button zum starten zugewiesen werden.</p>
<a routerLink="/VBAbutton" > Makro Start Button erstellen</a>
<p>Ende.</p>
