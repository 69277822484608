import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cookiepopup',
  templateUrl: './cookiepopup.component.html',
  styleUrls: ['./cookiepopup.component.scss']
})
export class CookiepopupComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
