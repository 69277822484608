<!-- //angemeldet in angular.json: scripts:"src/assets/js/cookiepage.js"  -->
<!-- //LOAD: index.html: HEAD  -->
<!-- 
<button onclick="testalert()">Try it</button>
 -->
<br>
<h1>Cookies</h1>
<p>
Diese Seite verwendet ohne Ihre Zustimmung nur technisch notwendige Cookies.
Alle weiteren Cookies erfordern Ihre Zustimmung. 
</p>
<p>
Cookie remove: Das Cookie wird zum Sitzungsende gelöscht.
</p>


<br>

<button onclick="cookieremove()">remove Cookie</button>



