import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-page-cookies',
  templateUrl: './page-cookies.component.html',
  styleUrls: ['./page-cookies.component.scss']
})
export class PageCookiesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
