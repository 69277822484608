

//OF service 


import { Injectable, Output, EventEmitter } from '@angular/core';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
 
@Injectable({
  providedIn: 'root'
})
export class DataserviceService {
  redirectUrl: string;
  
  //LIVE:
 baseUrl:string = "https://ofdev.de"
  //LOCAL:
 //baseUrl:string = "http://localhost"; //register php mail ERROR wenn local :D

@Output() getLoggedInName: EventEmitter<any> = new EventEmitter();
  
constructor(private httpClient : HttpClient) {

 
}

public userlogin(username, password) {
    return this.httpClient.post<any>(this.baseUrl + '/login.php', { username, password })
        .pipe(map(Usermodule => {
            this.setToken(Usermodule[0].name);
            this.getLoggedInName.emit(true);
            return Usermodule;
        }));
}

public userregistration(name,email,pwd) {
  return this.httpClient.post<any>(this.baseUrl + '/registration.php', { name,email, pwd })
      .pipe(map(Usermodule => {
          return Usermodule;
      }));
}

//OF mit changepassword.component.ts form etc.
public userchangepassword(username, password, newpassword){
  return this.httpClient.post<any>(this.baseUrl + '/changepassword.php', {username, password, newpassword })
      .pipe(map(Usermodule => {
        return Usermodule;
    }));
}
//OF for mylist.component.ts
public userpooldata(username, sqlspalte){
  return this.httpClient.post<any>(this.baseUrl + '/pool.php', {username, sqlspalte })
  .pipe(map(Usermodule => {
    return Usermodule;
}));
}

public getusersettings(username){
  return this.httpClient.post<any>(this.baseUrl + '/getusersettings.php', {username })
  .pipe(map(Usermodule => {
    return Usermodule;
}));
}


//OF mylist.component.ts additemtoDB
public additemtoDB(spalte,position,name,dauer, termin, menge, username){
  return this.httpClient.post<any>(this.baseUrl + '/additemtoDB.php', {spalte,position,name,dauer, termin, menge, username })
  .pipe(map(Usermodule => {
    return Usermodule;
}));
}
//OF mylist.component.ts save_changes_to_DB()
public save_changes_to_DB(username,datapool,datasp1,datasp2,datasp3){
  return this.httpClient.post<any>(this.baseUrl + '/save_changes_to_DB.php', {username,datapool,datasp1,datasp2,datasp3 })
  .pipe(map(Usermodule => {
    return Usermodule;
}));
}
//OF mylist.component.ts delete_from_DB()
public delete_from_DB(username,delIDs){
  return this.httpClient.post<any>(this.baseUrl + '/delete_from_DB.php', {username,delIDs })
  .pipe(map(Usermodule => {
    return Usermodule;
}));

}


public save_buttons_status_to_DB(username,viewDauer,viewMenge,viewTermin,viewSpalte1,viewSpalte2,viewSpalte3){
  return this.httpClient.post<any>(this.baseUrl + '/save_buttons_status_to_DB.php', {username,viewDauer,viewMenge,viewTermin,viewSpalte1,viewSpalte2,viewSpalte3})
  .pipe(map(Usermodule => {
    return Usermodule;
}));


}

//token
setToken(token: string) {
  localStorage.setItem('token', token);
}
 
getToken() {
  return localStorage.getItem('token');
}
 
deleteToken() {
  localStorage.removeItem('token');
}
 
isLoggedIn() {
  const usertoken = this.getToken();
  if (usertoken != null) {
    return true
  }
  return false;
}
 
}
