<br>
<h1>Tabelle sortieren</h1>
<div class="spaceH1"></div>

<p>
    Um Ihre Tabelle mit Spaltenüberschriften nach Spalte1 aufsteigend
     mit Spalte2 absteigend zu sortieren:
</p>


<iframe id=iframe1
    src="assets/txt/pageVBAsortieren/1.txt" 

    
></iframe>

<h2>Tabelle sortieren Beispiel:</h2>

<p>Sie möchten Ihre Tabelle sortieren?</p>

<h2>Schritt 1: Leere Arbeitsmappe erstellen (*.xlsm) und ein Modul einfügen</h2>
<p>Hinweis: <a routerLink="/VBAintro" > VBA Editor Wie? und Wo? </a> </p>
 

<h2>Beispiel-Tabelle mit erfundenen Daten.</h2>

<object data="assets/tabellen/Demostatistik.html" type="text/html"

  style="height:500px"


></object>

<h2>Schritt 2: Spalten finden</h2>  
<p>
    Als Basis wird hier <a routerLink="/Spaltenfinden"> Spalten finden</a> verwendet.
    Der Sub "Name" wir aber der Ordnung halber in sortieren geändert. 
</p>

<iframe id=iframe2
    src="assets/txt/pageVBAsortieren/2.txt" 

    
></iframe>

<h2>Schritt 3: sortieren</h2>  
<p>
    Um zu sortieren werden hier alle Zellen ausgewählt(.Cells steht für alle) danach 
    für die lesbarkeit ein Zeilenumbruch. Der Erste Schlüssel ist die Zelle 
    der Spaltenüberschrift Verkäufer die der Variable spnrVerkäufer zugewiesen wurde.
    Order1:=xlAscending steht für aufsteigend. Zur Demo wird noch ein Zweiter 
    Schlüssel in die nächste Zeile geschrieben, Key2 braucht Order"2" um "richtig" 
    zu sortieren. Ans Ende sollte  Header:=xlYes es gibt an das .Cells(1, spnrVerkäufer) 
    und .Cells(1, spnrPreis)  Überschriften sind. 

</p>

<iframe id=iframe3
    src="assets/txt/pageVBAsortieren/3.txt" 

    
></iframe>

<p>
    Nun wäre Ihre Tabelle fertig sortiert. Ihnen fallen nun Zeilen auf die
     Sie eigentlich nicht benötigen? Sie 
     können diese <a routerLink="/entfernen" > entfernen.</a>
     
</p>



<h2>Ende.</h2>
<br>
