

<br>
<h1>Demo List </h1>
<div class="spaceH1"></div>
<p> Dies ist eine Funktions-Demo zu  Mobile List </p>
<p> Ihre Eingaben können HIER leider nicht gespeichert werden!</p>
<!-- vereinfachte  veraltete version von page-cdk-drag  aka mobilelist -->

<!-- B U T T O  N S     teilweise diabled! -->

<br>
<br>
<div class="noselect">
 
<div style="width: 1000px;">
  
  <div class="BlockZarea"> 

  <form [formGroup]="angForm" (ngSubmit)="additem_to_DB(angForm)" autocomplete="off" class="noselect" >
      
    <div class="formfield">
    <span for="name">Name: *</span>
    <br>
    <input type="name" name="name" formControlName="name" autocomplete="off" class="form-control input-sm" placeholder="Name">
    </div> 
    
    <div class ="formfield">
    <span for="menge">Menge: </span>
    <br>
    <input type="menge" name="menge" formControlName="menge"  autocomplete="off" class="form-control input-sm" placeholder="Menge">
    </div>
    
    <div class ="formfield">
    <span for="dauer">Dauer: </span>
    <br>
    <input type="dauer" name="dauer" formControlName="dauer"  autocomplete="off" class="form-control input-sm" placeholder="Dauer">
    </div>
    
    <br>
    <br>
    <br>

    <div class ="formfield">
    <span for="termin">Termin: </span>
    <br>
    <input type="termin" name="termin" formControlName="termin"  autocomplete="off" class="form-control input-sm" placeholder="Termin">
    </div>

    <div class ="formfield">
    <span for="spalte">Spalte: </span>
    <br>
    <input type="spalte" name="spalte" formControlName="spalte"  autocomplete="off" class="form-control input-sm" placeholder="Spalte">
    </div>
    
    <br>
  
    <button type="submit"  [disabled]="!angForm.valid" 
      style="height: 40px; width: 150px; font-weight: bolder;">
        
        erstellen
    
    </button>
  
  </form>

</div>

<br>

<!-- toogle buttons für Ansicht -->
<div class="VIewButtonArea">   
  
  <!-- <button mat-raised-button (click)="toogle_Dauer()" class="Filterbutton" >  -->
  
  <button (click)="toogle_Dauer()" class="Filterbutton" id="btnDauer">
    
     Dauer: on/off
  
  </button>


  <button (click)="toogle_Menge()" class="Filterbutton" id="btnMenge" >
    
    Menge: on/off
 
  </button>

  <button (click)="toogle_Termin()" class="Filterbutton" id="btnTermin" >
    
    Termin: on/off
 
  </button>
</div>

<div class="VIewButtonArea">  
  <button (click)="toogle_spalte('spalte1')" class="Filterbutton" id="btnspalte1" >
    
    Spalte 1: on/off
 
  </button>

  <button (click)="toogle_spalte('spalte2')" class="Filterbutton" id="btnspalte2">
    
    Spalte 2: on/off
 
  </button>

  <button (click)="toogle_spalte('spalte3')" class="Filterbutton"id="btnspalte3" >
    
    Spalte 3: on/off
 
  </button>


  <!-- Save-Buttons-Status to DB -->
   <button (click)="save_buttons_status_to_DB()" class="Filterbutton" >
    
    Einstellung speichern
 
  </button> 

</div>   

  
<!-- SPALTEN  und Füllmethoden -->


<div class="example-container">
  
  <h2>Pool</h2>
  
  <div 
    id="pool"
    cdkDropList
    #poolList="cdkDropList"
    [cdkDropListData]="datapool[0]"
    
      [cdkDropListConnectedTo]="[einsList,zweiList,dreiList]" 

    class="example-list"
    (cdkDropListDropped)="drop($event)">
                                                      <!-- ?=true then..... :=else_for here heigth.px             -->
    <div class="example-box"
          
          *ngFor="let item of datapool[0]; let i =index" 
            cdkDrag
            id= {{i}}
            [ngStyle] =  "{'height.px' : (viewDauer == true) ? item.dauer * 1  : 60}" 
                                                            
                           
        >
        <div *ngIf="viewDauer == true && viewMenge == true && viewTermin == true; then thenBlock1"></div>
        <div *ngIf="viewDauer == false && viewMenge == true && viewTermin == true; then thenBlock2"></div>
        <div *ngIf="viewDauer == true && viewMenge == false && viewTermin == true; then thenBlock3"></div>
        <div *ngIf="viewDauer == true && viewMenge == true && viewTermin == false; then thenBlock4"></div>
        <div *ngIf="viewDauer == false && viewMenge == false && viewTermin == true; then thenBlock5"></div>
        <div *ngIf="viewDauer == true && viewMenge == false && viewTermin == false; then thenBlock6"></div>
        <div *ngIf="viewDauer == false && viewMenge == false && viewTermin == false; then thenBlock7"></div>
        <div *ngIf="viewDauer == false && viewMenge == true && viewTermin == false; then thenBlock8"></div>
        
        <ng-template #thenBlock1>
          {{"Name: " + item.name}} <br>
          {{"Menge: " + item.menge}} <br>
          {{"Dauer: " + item.dauer}} <br>
          {{"Termin: " + item.termin}} <br>
        </ng-template>
        <ng-template #thenBlock2>
          {{"Name: " + item.name}} <br>
          {{"Menge: " + item.menge}} <br>
          <!-- {{"Dauer: " + item.dauer}} <br> -->
          {{"Termin: " + item.termin}} <br>
        </ng-template>
        <ng-template #thenBlock3>
          {{"Name: " + item.name}} <br>
          <!-- {{"Menge: " + item.menge}} <br> -->
          {{"Dauer: " + item.dauer}} <br> 
          {{"Termin: " + item.termin}} <br>
        </ng-template>
        <ng-template #thenBlock4>
          {{"Name: " + item.name}} <br>
          {{"Menge: " + item.menge}} <br> 
          {{"Dauer: " + item.dauer}} <br> 
          <!-- {{"Termin: " + item.termin}} <br> -->
        </ng-template>
        <ng-template #thenBlock5>
          {{"Name: " + item.name}} <br>
          <!-- {{"Menge: " + item.menge}} <br>  -->
          <!-- {{"Dauer: " + item.dauer}} <br>  -->
          {{"Termin: " + item.termin}} <br>
        </ng-template>
        <ng-template #thenBlock6>
          {{"Name: " + item.name}} <br>
          <!-- {{"Menge: " + item.menge}} <br>  -->
          {{"Dauer: " + item.dauer}} <br>  
          <!-- {{"Termin: " + item.termin}} <br> -->
        </ng-template>
        <ng-template #thenBlock7>
          {{"Name: " + item.name}} <br>
          <!-- {{"Menge: " + item.menge}} <br>  -->
          <!-- {{"Dauer: " + item.dauer}} <br>  -->
          <!-- {{"Termin: " + item.termin}} <br> -->
        </ng-template>
        <ng-template #thenBlock8>
          {{"Name: " + item.name}} <br>
          {{"Menge: " + item.menge}} <br>
          <!-- {{"Dauer: " + item.dauer}} <br>  -->
          <!-- {{"Termin: " + item.termin}} <br> -->
        </ng-template>
        

        <button (click)="delete_item($event)">x</button>  
    </div>
  
  </div>

</div>

<div class="example-container">
  
  <h2>Eins</h2>

  <div
    id = "spalte1"
    cdkDropList
    #einsList="cdkDropList"
    [cdkDropListData]="datasp1[0]"

      [cdkDropListConnectedTo]="[poolList,zweiList,dreiList]"

    class="example-list"
    (cdkDropListDropped)="drop($event)">
    <div class="example-box" 
          *ngFor="let item of datasp1[0]; let i =index" 
          cdkDrag
          id= {{i}}
          [ngStyle] =  "{'height.px' : (viewDauer == true) ? item.dauer * 1  : 60}" 
                            
        >
        <div *ngIf="viewDauer == true && viewMenge == true && viewTermin == true; then thenBlock1"></div>
        <div *ngIf="viewDauer == false && viewMenge == true && viewTermin == true; then thenBlock2"></div>
        <div *ngIf="viewDauer == true && viewMenge == false && viewTermin == true; then thenBlock3"></div>
        <div *ngIf="viewDauer == true && viewMenge == true && viewTermin == false; then thenBlock4"></div>
        <div *ngIf="viewDauer == false && viewMenge == false && viewTermin == true; then thenBlock5"></div>
        <div *ngIf="viewDauer == true && viewMenge == false && viewTermin == false; then thenBlock6"></div>
        <div *ngIf="viewDauer == false && viewMenge == false && viewTermin == false; then thenBlock7"></div>
        <div *ngIf="viewDauer == false && viewMenge == true && viewTermin == false; then thenBlock8"></div>
        
        <ng-template #thenBlock1>
          {{"Name: " + item.name}} <br>
          {{"Menge: " + item.menge}} <br>
          {{"Dauer: " + item.dauer}} <br>
          {{"Termin: " + item.termin}} <br>
        </ng-template>
        <ng-template #thenBlock2>
          {{"Name: " + item.name}} <br>
          {{"Menge: " + item.menge}} <br>
          <!-- {{"Dauer: " + item.dauer}} <br> -->
          {{"Termin: " + item.termin}} <br>
        </ng-template>
        <ng-template #thenBlock3>
          {{"Name: " + item.name}} <br>
          <!-- {{"Menge: " + item.menge}} <br> -->
          {{"Dauer: " + item.dauer}} <br> 
          {{"Termin: " + item.termin}} <br>
        </ng-template>
        <ng-template #thenBlock4>
          {{"Name: " + item.name}} <br>
          {{"Menge: " + item.menge}} <br> 
          {{"Dauer: " + item.dauer}} <br> 
          <!-- {{"Termin: " + item.termin}} <br> -->
        </ng-template>
        <ng-template #thenBlock5>
          {{"Name: " + item.name}} <br>
          <!-- {{"Menge: " + item.menge}} <br>  -->
          <!-- {{"Dauer: " + item.dauer}} <br>  -->
          {{"Termin: " + item.termin}} <br>
        </ng-template>
        <ng-template #thenBlock6>
          {{"Name: " + item.name}} <br>
          <!-- {{"Menge: " + item.menge}} <br>  -->
          {{"Dauer: " + item.dauer}} <br>  
          <!-- {{"Termin: " + item.termin}} <br> -->
        </ng-template>
        <ng-template #thenBlock7>
          {{"Name: " + item.name}} <br>
          <!-- {{"Menge: " + item.menge}} <br>  -->
          <!-- {{"Dauer: " + item.dauer}} <br>  -->
          <!-- {{"Termin: " + item.termin}} <br> -->
        </ng-template>
        <ng-template #thenBlock8>
          {{"Name: " + item.name}} <br>
          {{"Menge: " + item.menge}} <br>
          <!-- {{"Dauer: " + item.dauer}} <br>  -->
          <!-- {{"Termin: " + item.termin}} <br> -->
        </ng-template>
        <button (click)="delete_item($event)">x</button>    
     </div>
  </div>
</div>

<div class="example-container">
  
  <h2>Zwei</h2>

  <div
  id = "spalte2"
    cdkDropList
    #zweiList="cdkDropList"
    [cdkDropListData]="datasp2[0]"

      [cdkDropListConnectedTo]="[poolList,einsList,dreiList]"

    class="example-list"
    (cdkDropListDropped)="drop($event)">
    <div class="example-box" 
          *ngFor="let item of datasp2[0]; let i = index" 
            cdkDrag 
            id= {{i}}
            [ngStyle] =  "{'height.px' : (viewDauer == true) ? item.dauer * 1  : 60}" 
                            
        >
        <div *ngIf="viewDauer == true && viewMenge == true && viewTermin == true; then thenBlock1"></div>
        <div *ngIf="viewDauer == false && viewMenge == true && viewTermin == true; then thenBlock2"></div>
        <div *ngIf="viewDauer == true && viewMenge == false && viewTermin == true; then thenBlock3"></div>
        <div *ngIf="viewDauer == true && viewMenge == true && viewTermin == false; then thenBlock4"></div>
        <div *ngIf="viewDauer == false && viewMenge == false && viewTermin == true; then thenBlock5"></div>
        <div *ngIf="viewDauer == true && viewMenge == false && viewTermin == false; then thenBlock6"></div>
        <div *ngIf="viewDauer == false && viewMenge == false && viewTermin == false; then thenBlock7"></div>
        <div *ngIf="viewDauer == false && viewMenge == true && viewTermin == false; then thenBlock8"></div>
        
        <ng-template #thenBlock1>
          {{"Name: " + item.name}} <br>
          {{"Menge: " + item.menge}} <br>
          {{"Dauer: " + item.dauer}} <br>
          {{"Termin: " + item.termin}} <br>
        </ng-template>
        <ng-template #thenBlock2>
          {{"Name: " + item.name}} <br>
          {{"Menge: " + item.menge}} <br>
          <!-- {{"Dauer: " + item.dauer}} <br> -->
          {{"Termin: " + item.termin}} <br>
        </ng-template>
        <ng-template #thenBlock3>
          {{"Name: " + item.name}} <br>
          <!-- {{"Menge: " + item.menge}} <br> -->
          {{"Dauer: " + item.dauer}} <br> 
          {{"Termin: " + item.termin}} <br>
        </ng-template>
        <ng-template #thenBlock4>
          {{"Name: " + item.name}} <br>
          {{"Menge: " + item.menge}} <br> 
          {{"Dauer: " + item.dauer}} <br> 
          <!-- {{"Termin: " + item.termin}} <br> -->
        </ng-template>
        <ng-template #thenBlock5>
          {{"Name: " + item.name}} <br>
          <!-- {{"Menge: " + item.menge}} <br>  -->
          <!-- {{"Dauer: " + item.dauer}} <br>  -->
          {{"Termin: " + item.termin}} <br>
        </ng-template>
        <ng-template #thenBlock6>
          {{"Name: " + item.name}} <br>
          <!-- {{"Menge: " + item.menge}} <br>  -->
          {{"Dauer: " + item.dauer}} <br>  
          <!-- {{"Termin: " + item.termin}} <br> -->
        </ng-template>
        <ng-template #thenBlock7>
          {{"Name: " + item.name}} <br>
          <!-- {{"Menge: " + item.menge}} <br>  -->
          <!-- {{"Dauer: " + item.dauer}} <br>  -->
          <!-- {{"Termin: " + item.termin}} <br> -->
        </ng-template>
        <ng-template #thenBlock8>
          {{"Name: " + item.name}} <br>
          {{"Menge: " + item.menge}} <br>
          <!-- {{"Dauer: " + item.dauer}} <br>  -->
          <!-- {{"Termin: " + item.termin}} <br> -->
        </ng-template>
        <button (click)="delete_item($event)">x</button>     
     </div>
  </div>
</div>

<div class="example-container">
  
  <h2>Drei</h2>

  <div
    id = "spalte3"
    cdkDropList
    #dreiList="cdkDropList"
    [cdkDropListData]="datasp3[0]"

      [cdkDropListConnectedTo]="[poolList,einsList,zweiList]"

    class="example-list"
    (cdkDropListDropped)="drop($event)">
    <div class="example-box" 
          *ngFor="let item of datasp3[0]; let i = index" 
          cdkDrag 
          id= {{i}}
          [ngStyle] =  "{'height.px' : (viewDauer == true) ? item.dauer * 1  : 60}" 
                            
        >
        <div *ngIf="viewDauer == true && viewMenge == true && viewTermin == true; then thenBlock1"></div>
        <div *ngIf="viewDauer == false && viewMenge == true && viewTermin == true; then thenBlock2"></div>
        <div *ngIf="viewDauer == true && viewMenge == false && viewTermin == true; then thenBlock3"></div>
        <div *ngIf="viewDauer == true && viewMenge == true && viewTermin == false; then thenBlock4"></div>
        <div *ngIf="viewDauer == false && viewMenge == false && viewTermin == true; then thenBlock5"></div>
        <div *ngIf="viewDauer == true && viewMenge == false && viewTermin == false; then thenBlock6"></div>
        <div *ngIf="viewDauer == false && viewMenge == false && viewTermin == false; then thenBlock7"></div>
        <div *ngIf="viewDauer == false && viewMenge == true && viewTermin == false; then thenBlock8"></div>
        
        <ng-template #thenBlock1>
          {{"Name: " + item.name}} <br>
          {{"Menge: " + item.menge}} <br>
          {{"Dauer: " + item.dauer}} <br>
          {{"Termin: " + item.termin}} <br>
        </ng-template>
        <ng-template #thenBlock2>
          {{"Name: " + item.name}} <br>
          {{"Menge: " + item.menge}} <br>
          <!-- {{"Dauer: " + item.dauer}} <br> -->
          {{"Termin: " + item.termin}} <br>
        </ng-template>
        <ng-template #thenBlock3>
          {{"Name: " + item.name}} <br>
          <!-- {{"Menge: " + item.menge}} <br> -->
          {{"Dauer: " + item.dauer}} <br> 
          {{"Termin: " + item.termin}} <br>
        </ng-template>
        <ng-template #thenBlock4>
          {{"Name: " + item.name}} <br>
          {{"Menge: " + item.menge}} <br> 
          {{"Dauer: " + item.dauer}} <br> 
          <!-- {{"Termin: " + item.termin}} <br> -->
        </ng-template>
        <ng-template #thenBlock5>
          {{"Name: " + item.name}} <br>
          <!-- {{"Menge: " + item.menge}} <br>  -->
          <!-- {{"Dauer: " + item.dauer}} <br>  -->
          {{"Termin: " + item.termin}} <br>
        </ng-template>
        <ng-template #thenBlock6>
          {{"Name: " + item.name}} <br>
          <!-- {{"Menge: " + item.menge}} <br>  -->
          {{"Dauer: " + item.dauer}} <br>  
          <!-- {{"Termin: " + item.termin}} <br> -->
        </ng-template>
        <ng-template #thenBlock7>
          {{"Name: " + item.name}} <br>
          <!-- {{"Menge: " + item.menge}} <br>  -->
          <!-- {{"Dauer: " + item.dauer}} <br>  -->
          <!-- {{"Termin: " + item.termin}} <br> -->
        </ng-template>
        <ng-template #thenBlock8>
          {{"Name: " + item.name}} <br>
          {{"Menge: " + item.menge}} <br>
          <!-- {{"Dauer: " + item.dauer}} <br>  -->
          <!-- {{"Termin: " + item.termin}} <br> -->
        </ng-template>
        <button (click)="delete_item($event)">x</button>     
     </div>
  </div>
</div>

</div>

<!-- save to DB Button -->
<button style="width: 120px; height: 50px; background-color: gray; font-size: larger; color:white;" 

      (click)="save_changes_to_DB($event)">Änderungen speichern
    </button> 

</div>
