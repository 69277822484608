<h1></h1>



<div id="sidebar_all_as_flex" class="sidebar_all_as_flex" style="display:flex">



  <div id="sidebar_frame_flex" class="sidebar_frame_flex">




    <button id="btn_sidebar_home" class="sidebar_btn" onclick="sidebar_content_show(event)">Home</button>

    <div id="sidebar_home" class="sidebar_content">

      <a routerLink="/home"> Home </a>


    </div>


    <button id="btn_sidebar_vba" class="sidebar_btn" onclick="sidebar_content_show(event)"> VBA (Excel)</button>

    <div id="sidebar_vba" class="sidebar_content">

      <a routerLink="/VBAintro"> VBA Editor Wie? und Wo? </a>
      <a routerLink="/VBAbutton"> Makro Start Button erstellen</a>
      <a routerLink="/VBAcopyTO"> öffnen & kopieren & einfügen</a>
      <a routerLink="/Spaltenfinden">Spalten finden</a>
      <a routerLink="/Spaltenkopieren">Spalten kopieren</a>
      <a routerLink="/sortieren"> sortieren</a>
      <a routerLink="/entfernen"> entfernen</a>
      <a routerLink="/VBAkommentar">Kommentare </a>
      <a routerLink="/VBAWertefinden">Werte finden </a>

    </div>


    <button id="btn_sidebar_web" class="sidebar_btn" onclick="sidebar_content_show(event)"> WEB</button>

    <div id="sidebar_web" class="sidebar_content">

      <a routerLink="/webplan"> Webplan </a>
      <!--<a routerLink="/demolist"> Demolist </a>-->


    </div>



  </div>

  <div id="sidebar_button_flex" class="sidebar_button_flex_bar">

    <button id="btn_sidebar_show_toggle" onclick="sidebar_show_toggle()" class="sidebar_button_flex_btn"> -></button>

  </div>

</div>


