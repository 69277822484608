<!--   Code in different.js   -->
<!--   sidebar_frame_show_toggle(event): hide other sidebar content in sidebar.component.html -->


<div id ="navbar" class="navbar">

  <button id="btn_nav_home" class="navbar_btn" onclick="sidebar_content_show(event)">Home</button>

  <button id="btn_nav_vba" class="navbar_btn" onclick="sidebar_content_show(event)">VBA(Excel) </button>

  <button id="btn_nav_web" class="navbar_btn" onclick="sidebar_content_show(event)">Web</button>

   
</div>


<!--<div>

    <a routerLink="/VBAintro"> VBA Editor Wie? und Wo? </a>
    <a routerLink="/VBAbutton"> Makro Start Button erstellen</a>
    <a routerLink="/VBAcopyTO"> öffnen & kopieren & einfügen</a>
    <a routerLink="/Spaltenfinden">Spalten finden</a>
    <a routerLink="/Spaltenkopieren">Spalten kopieren</a>

    <a routerLink="/sortieren"> sortieren</a>
    <a routerLink="/entfernen"> entfernen</a>
    <a routerLink="/VBAkommentar">Kommentare </a>

    <a routerLink="/VBAWertefinden"> Werte finden</a>

</div>-->
