import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CdkDrag, CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';

//Update 12.11.2023 try to use server only :)
//PHP einbindung per constructor=> startet items.php backend aus: httpdocs?!


@Component({
  selector: 'app-web-plan',
  templateUrl: './web-plan.component.html',
  styleUrls: ['./web-plan.component.scss']
})




export class WebPlanComponent implements OnInit {


  title = 'blockZ';
  data = [];
  //constructor lädt auch ohne Seite betreten vor?
  constructor(private http: HttpClient) {

    // LOCAL TEST
    //this.http.get('http://localhost/items.php').subscribe(data => {
    // REAL !!!! 
    this.http.get('https://ofdev.de/get_dl_pool_public.php').subscribe(
      data => {

        this.data_dl_pool.push(data);
        console.log(this.data);

      },

      error => console.error(error)

    );

    this.http.get('https://ofdev.de/get_dl_s1_public.php').subscribe(
      data => {

        this.data_dl_s1.push(data);
        console.log(this.data);

      },

      error => console.error(error)

    );

    this.http.get('https://ofdev.de/get_dl_s2_public.php').subscribe(
      data => {

        this.data_dl_s2.push(data);
        console.log(this.data);

      },

      error => console.error(error)

    );

    this.http.get('https://ofdev.de/get_dl_s3_public.php').subscribe(
      data => {

        this.data_dl_s3.push(data);
        console.log(this.data);

      },

      error => console.error(error)

    );


    

  }


  //drop List pool, s1,s2,s3  dl_s1
  data_dl_pool = [];
  data_dl_s1 = [];
  data_dl_s2 = [];
  data_dl_s3 = [];

  


  ngOnInit(): void {

    document.getElementById("footer").style.visibility = "hidden";
    



  }

  


  // DRAG  DROP 
  drop(event: CdkDragDrop<string[]>) {

    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
      console.log("Drop: " + event.container.data, event.previousIndex, event.currentIndex);

    } else {

      transferArrayItem(event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex);
      console.log("Drop: " + event.previousContainer.data, event.container.data, event.previousIndex, event.currentIndex);

    }


  }
















}
